/**
 * Service schema for group merge client.
 *
 * @module
 */
const uuidRegExp = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-4][0-9a-f]{3}-[0-9a-f]{4}-[0-9a-f]{12}$/i;

export default {
    interface_version : "0.1.0",
    create : {
        group_merge_task : {
            requestMapSchema : {
                properties : {
                    group_id : { type : "string", pattern : uuidRegExp },
                    merged_group_id : { type : "string", pattern : uuidRegExp },
                    password : { type : "string" }
                },
                required : [ "group_id", "merged_group_id", "password" ],
                additionalProperties : false
            },
            scope : "administer",
            handler : "route"
        }
    }
};
