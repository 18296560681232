<template>
  <v-btn @click="handleButtonClick" class="shio-upload-button" :disabled="!pickerApiLoaded" depressed>
    <slot/>
  </v-btn>
</template>
<script>

    import util from "../../../../util/util";
    import axios from "axios";
    let client;
    export default {
        data()
        {
            return {
                picked : {},
                pickerApiLoaded : false,
                oauthToken : '',
                code : '',
                config : {
                    // The Browser API key obtained from the Google API Console.
                    developerKey : process.env.VUE_APP_GOOGLE_DEVELOPER_KEY,
                    // The Client ID obtained from the Google API Console. Replace with your own Client ID.
                    clientId : process.env.VUE_APP_GOOGLE_CLIENT_ID,
                    //The App ID obtained from IAM & Admin > Settings > Project number
                    appId : process.env.VUE_APP_GOOGLE_APP_ID,
                    // Scope to use to access user's drive.
                    scope : 'https://www.googleapis.com/auth/drive.file'
                }
            }
        },
        methods : {
            handleAuthResult( authResult )
            {
                if( authResult && !authResult.error )
                {
                    this.oauthToken = authResult.access_token;
                    this.createPicker();
                }
            },
            handleButtonClick()
            {
                this.$store.shioCoreClient.retrieveGoogleAccessToken( $cc_user_info.organization.uuid ).then( r =>
                {
                    if( r.access_token )
                    {
                        this.handleAuthResult( r );
                    }
                    else
                    {
                        this.$error( this.$t( 'message.Error!Unexpected' ) );
                    }
                } ).catch( e =>
                {
                    switch( e.code_key )
                    {
                        case 400 :
                            client.requestCode();
                            break;
                        case 404 :
                            client.requestCode();
                            break;
                        default :
                            this.$error( this.$t( 'message.Error!Unexpected' ) );
                    }
                } );
            },
            createGoogleAccessToken()
            {
                this.$store.shioCoreClient.createGoogleAccessToken( $cc_user_info.organization.uuid, this.code ).then( r =>
                {
                    if( r.access_token )
                    {
                        this.handleAuthResult( r );
                    }
                    else
                    {
                        this.$error( this.$t( 'message.Error!Unexpected' ) );
                    }
                } ).catch( e =>
                {
                    this.$error( this.$t( 'message.Error!Unexpected' ) );
                } );
            },
            createPicker()
            {
                if( this.pickerApiLoaded && this.oauthToken )
                {
                    const view = new window.google.picker.DocsView( window.google.picker.ViewId.DOCS );
                    view.setMimeTypes(
                    "application/pdf,application/vnd.google-apps.document,application/vnd.google-apps.spreadsheet" );
                    view.setMode( window.google.picker.DocsViewMode.LIST );
                    view.setIncludeFolders( true );
                    const picker = new window.google.picker.PickerBuilder().setOrigin( window.location.protocol
                                                                                       + '//'
                                                                                       + window.location.host ).enableFeature(
                    window.google.picker.Feature.MULTISELECT_ENABLED ).setSize( 566350 ).setAppId( this.config.appId ).addView( view ).setLocale(
                    util.getLocale() ).setOAuthToken( this.oauthToken ).setDeveloperKey( this.config.developerKey ).setCallback(
                    this.pickerCallback ).build();
                    picker.setVisible( true );
                }
            },
            pickerCallback( data )
            {
                if( data[ window.google.picker.Response.ACTION ] == window.google.picker.Action.PICKED )
                {
                    let docsLeft = data.docs.length;
                    const axiosHeaders = { Authorization : `Bearer ${this.oauthToken}` };
                    data.docs.forEach( doc =>
                    {
                        const isPdf = (doc.mimeType === 'application/pdf');
                        const addUrl = isPdf ? "?alt=media" : "/export?mimeType=application/pdf";
                        axios( {
                            url : 'https://www.googleapis.com/drive/v2/files/' + doc.id + addUrl,
                            method : "GET",
                            responseType : 'blob',
                            headers : axiosHeaders,
                        } ).then( response =>
                        {
                            docsLeft--;
                            if( response.data.type === 'application/pdf' )
                            {
                                this.$emit( "picked",
                                { blob : response.data, name : isPdf ? doc.name : doc.name + ".pdf" } );
                            }
                            if( docsLeft === 0 )
                            {
                                this.$emit( 'finished' );
                            }
                        } ).catch( () =>
                        {
                            docsLeft--;
                            if( docsLeft === 0 )
                            {
                                this.$emit( 'finished' );
                            }
                        } );
                    } );
                }
            },
        },
        mounted()
        {
            if( !window.gapi )
            {
                return console.warn( 'Google API not loaded' );
            }
            if( !window.google )
            {
                return console.warn( 'Google Client not loaded' );
            }
            client = window.google.accounts.oauth2.initCodeClient( {
                client_id : this.config.clientId,
                scope : this.config.scope,
                ux_mode : 'popup',
                callback : ( response ) =>
                {
                    this.code = response.code;
                    this.createGoogleAccessToken();
                },
            } );
            window.gapi.load( 'picker', () =>
            {
                this.pickerApiLoaded = true;
            } );
        }
    }

</script>