import { render, staticRenderFns } from "./ClientCredentials.vue?vue&type=template&id=ca989836"
import script from "./ClientCredentials.vue?vue&type=script&lang=js"
export * from "./ClientCredentials.vue?vue&type=script&lang=js"
import style0 from "./ClientCredentials.vue?vue&type=style&index=0&id=ca989836&prod&lang=sass"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports