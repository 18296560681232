import { render, staticRenderFns } from "./RequestAnAccessToken.vue?vue&type=template&id=3db2f306&scoped=true"
import script from "./RequestAnAccessToken.vue?vue&type=script&lang=js"
export * from "./RequestAnAccessToken.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3db2f306",
  null
  
)

export default component.exports