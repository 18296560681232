<template>
  <div class="shio-download-zone pa-5 ma-2"
       @drop.prevent="onDroppedFiles"
       @dragover.prevent="highlight"
       @dragenter.prevent="highlight"
       @dragleave.prevent="unhighlight"
       :class="{ shio_download_zone_highlight : highlighted }">
    <slot>
    </slot>
  </div>
</template>

<script>
export default {
    data()
    {
        return {
            highlighted : false,
        }
    },
    methods: {
        /**
         * Check if all the dropped files are pdf and if yes emit files array
         * @param e
         */
        onDroppedFiles( e )
        {
            this.unhighlight();
            const files = Array.from( e.dataTransfer.files );
            if ( files.some( file => file.type !=='application/pdf' ) )
            {
                this.$error( this.$t( 'signflow.OnlyPdf' ) )
                return;
            }
            this.$emit( 'dropped', files );
        },
        /**
         * Set highlighted style for the drop zone
         */
        highlight()
        {
            this.highlighted = true;
        },
        /**
         * Set unhighlighted style for the drop zone
         */
        unhighlight()
        {
            this.highlighted = false;
        }
    }

}
</script>

<style scoped lang="sass">
@import "../../../../styles/style"

.shio-download-zone
  border-color: $light-grey
  border-width: 2px
  border-style: dashed

.shio_download_zone_highlight
  border-color: orange
  background-color: #fda

</style>
