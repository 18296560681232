<template>
  <v-card dark class="app-token-card pa-0" v-bind:class="{ invalidCard: !entity.active }" @click="$emit( 'click' )">
    <v-row no-gutters align="center">
      <v-col class="sh-user-card-icon">
        <v-row align="center" justify="center" class="fill-height">
          <v-icon size="64" color="white" class="pa-4">mdi-star</v-icon>
        </v-row>
      </v-col>
      <v-col>
        <v-card flat light tile class="sh-integrations-user-card ma-auto pa-4">
          <v-container fill-height class="text-left">
            <v-row>
              <v-col cols="12" class="app-info">
                <div class="client-name">{{ entity.display_name }}</div>
                <div class="client-domain">{{ entity.domain }}</div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <div class="app-chip">
                <v-chip v-if="!entity.active" color="info">{{ $t( 'client.UnverifiedApp' )}}</v-chip>
                </div>
                <div class="app-chip">
                  <v-chip v-if="entity.active" color="secondary">{{ $t( 'client.VerifiedApp' )}}</v-chip>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
    import util from "../../../util/util";
    export default {
        props : {
            entity : Object
        },
        data()
        {
            return {
            }
        },
        methods :
        {
        }
    }
</script>

<style lang="sass" scoped>
  @import "../../../styles/variables"

  .client-name
    font-weight: bold
    font-size: 20px
    overflow: hidden
    white-space: nowrap
    text-overflow: ellipsis

  .app-info
    max-width: 250px

  .client-domain
    font-size: small

  .invalidCard
    background-color: $dark-grey

  .app-chip .v-chip
    color: white
    padding: 15px
</style>
