/**
 * Service schema for user (signed-in) client.
 *
 * @module
 */
export default {
    interface_version : "1.0.0",
    create : {
        password_update_token : {
            requestMapSchema : {
                type : "object",
                properties : {
                    email : { type : "string", format : "email" },
                    scope : { type : "string" }
                },
                additionalProperties : false,
                required : [ "email" ]
            },
            handler : "route"
        },
        suppression : {
            requestMapSchema : {
                type : "object",
                properties : {
                    recipient_email : {
                        type : "string",
                        format : "email"
                    }
                },
                additionalProperties : false,
                required : [ "recipient_email" ]
            },
            handler : "route"
        }
    },
    delete : {
        suppression : {
            requestMapSchema : {
                type : "object",
                properties : {
                    recipient_email : {
                        type : "string",
                        format : "email"
                    }
                },
                additionalProperties : false,
                required : [ "recipient_email" ]
            },
            handler : "route"
        }
    }
};
