<template>
  <v-card flat class="pt-4 ma-auto maxWidth-1100">
    <v-overlay
        v-if="stripePaymentOn && ( !subscription.has_trial_period && !subscription.has_valid_subscription && !subscription.has_payment_past_due
        && !subscription.incomplete_subscription ) || ( stripePaymentOn && subscription.incomplete_subscription && !subscription.default_pm )"
        :absolute="absolute"
        :value="overlay"
        class="no-active-subscription-overlay"
    >
      <p class="font-weight-bold no-active-subscription-notice" v-if="!is_subscription_admin">{{ $t( "signedin.ContactAdmin" ) }}</p>
      <p class="font-weight-bold no-active-subscription-notice" v-if="is_subscription_admin">{{ $t( "signedin.PleaseAddValidCPayment" ) }}</p>
    </v-overlay>
    <div v-if="!choose_invitation_template">
      <div>
        <v-alert
            v-if="stripePaymentOn && subscription.has_trial_period && !subscription.hideBanner && subscription.trial_days_left <=14"
            color="info"
            dark
            dismissible
            @click="hideBanner"
            class="text-left"
        >
          {{ $t( "signedin.FreeTrialAlert1" ) }} {{subscription.trial_days_left}} {{ $t( "signedin.FreeTrialAlert2" ) }}
        </v-alert>
        <v-alert
            v-if="stripePaymentOn && subscription.has_payment_past_due && !subscription.hideBanner"
            color="info"
            dark
            dismissible
            @click="hideBanner"
            class="text-left"
        >
          {{ $t( "signedin.PaymentOverdueAlert" ) }}
        </v-alert>
        <v-alert
            v-if="stripePaymentOn && subscription.incomplete_subscription && is_subscription_admin && !subscription.hideBanner"
            color="info"
            dark
            dismissible
            @click="hideBanner"
            class="text-left"
        >
          {{ $t( "signedin.PleaseConfirmPaymentMainPage" ) }}
        </v-alert>
        <v-row no-gutters>
          <h1 class="shio-section-title">{{ $t( "signflow.WhoIsSigning" ) }}</h1>
        </v-row>
        <v-item-group mandatory v-model="flowType" @change="setFlowType()" class="pt-5"
                      active-class="shio-choose-flow-active">
          <v-row no-gutters>
            <v-col cols="12" sm="4" class="pa-3" hidden>
              <v-item
                  value="noflow"
                  v-slot:default="{ active, toggle }">
                <v-btn block @click="toggle" :input-value="active" class="shio-choose-flow pa-4" height="100%"
                       :ripple="false" elevation="7">Hidden
                </v-btn>
              </v-item>
            </v-col>
            <v-col cols="12" sm="4" class="pa-3">
              <v-item
                  value="only_me"
                  v-slot:default="{ active, toggle }">
                <v-btn block @click="toggle" :input-value="active" class="shio-choose-flow pa-4" height="100%"
                       :ripple="false" elevation="7">{{ $t( "signflow.OnlyMe" ) }}
                </v-btn>
              </v-item>
            </v-col>
            <v-col cols="12" sm="4" class="pa-3">
              <v-item
                  value="me_and_others"
                  v-slot:default="{ active, toggle }">
                <v-btn block @click="toggle" :input-value="active" class="shio-choose-flow pa-4" height="100%"
                       :ripple="false" elevation="7">{{ $t( "signflow.MeAndOthers" ) }}
                </v-btn>
              </v-item>
            </v-col>
            <v-col cols="12" sm="4" class="pa-3">
              <v-item
                  value="only_others"
                  v-slot:default="{ active, toggle }">
                <v-btn block @click="toggle" :input-value="active" class="shio-choose-flow pa-4" height="100%"
                       :ripple="false" elevation="7">{{ $t( "signflow.OnlyOthers" ) }}
                </v-btn>
              </v-item>
            </v-col>
          </v-row>
        </v-item-group>
        <div>
          <v-row no-gutters>
            <v-col v-for="( signer, index ) in signers" cols="12" sm="6" :key="signer.$key">
              <v-card class="pa-2 ma-3" :color="index > max_signers - 1 ? '#f2f2f2' : '#ffffff'">
                <v-form v-model="signer.valid">
                  <v-icon class="float-right" @click="removeSigner( signer, index )">mdi-close</v-icon>
                  <div>
                    <v-text-field class="shio-sign-card-input" v-model="signer.signerName"
                                  :rules="[ validateFullName, validateSignerName ]"
                                  :label="$t( 'signflow.SignersName' )"
                                  :readonly="signer.signerType === 'me'"/>
                  </div>
                  <div>
                    <v-text-field class="shio-sign-card-input" type="email" v-model="signer.signerEmail"
                                  @change="removeZeroSpace(signer, index)"
                                  :rules="[ validateEmail, signerIsNew ]"
                                  @input="checkSignerEmail( signer, index)"
                                  :label="$t( 'signflow.SignersEmail' )" :readonly="signer.signerType === 'me'"/>
                  </div>
                  <div class="shio-sign-card-input language-select"
                       :class="{ shio_invisible_content : signer.signerType === 'me' }">
                    <v-select
                        v-model="signer.locale"
                        :label="$t( 'signedin.Locales' )"
                        :items="language_options"
                        item-text="language"
                        item-value="locale"
                        :disabled="signer.signerType === 'me'">
                    </v-select>
                  </div>
                  <div style="text-align: left"
                       v-if="showOnlyMeSignatureSelector">
                    <v-autocomplete
                        v-model="signer.allowedSignatureTypes"
                        :items="localizedOnlyMeSignatureTypes"
                        item-text="text"
                        item-value="value"
                        dense
                        chips
                        small-chips
                        :label="$t( 'signflow.AllowedSignatureTypes' )"
                        @input="onOnlyMeSignatureTypesSelect"
                        multiple>
                    </v-autocomplete>
                  </div>
                  <div style="text-align: left" v-if="flowType !== 'only_me' && signatureLevel === 'advanced'">
                    <v-autocomplete
                        v-model="signer.allowedSignatureTypes"
                        :items="localizedSignatureTypes"
                        item-text="text"
                        item-value="value"
                        dense
                        chips
                        small-chips
                        :label="$t( 'signflow.AllowedSignatureTypes' )"
                        :disabled="localizedSignatureTypes.length < 2"
                        @change=""
                        multiple>
                    </v-autocomplete>
                  </div>
                </v-form>
              </v-card>
            </v-col>
          </v-row>
          <div v-if="flowType !== 'noflow'">
            <v-row class="pa-3">
              <v-col cols="12">
                <v-row>
                  <v-col cols="auto" class="pa-3">
                    <v-tooltip bottom :disabled="signers_less">
                      <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <v-btn
                          v-if="( enabledLevels.indexOf( 'advanced' ) !== -1 )  && ( ( flowType === 'me_and_others' ) || ( flowType === 'only_others' ) )"
                          class="alternative-primary-btn" block @click="toggleSignatureTypesDialog()"
                          :disabled="!signers_less">
                        {{ $t( 'signflow.SelectSignatureTypes' ) }}
                      </v-btn>
                    </span>
                      </template>
                      <span v-if="!signers_less_exact"> {{ $t( 'signflow.MaxSigners' ) }} {{ max_signers}}.
                        {{ $t( 'signflow.MaxSigners1' ) }} {{ max_signers_diff }} {{ $t( 'signflow.MaxSigners2' ) }}
                      </span>
                      <span v-if="signers_less_exact && !signers_less"> {{ $t( 'signflow.MaxSigners' ) }} {{ max_signers}}. {{ $t( 'signflow.MaxSigners3' ) }}
                  </span>
                    </v-tooltip>
                  </v-col>
                  <v-col class="grow"></v-col>
                  <v-col v-if="(flowType === 'me_and_others') || (flowType === 'only_others')" cols="auto" class="pa-3">
                    <v-tooltip bottom :disabled="signers_less">
                    <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                          <v-btn class="alternative-primary-btn" block @click="toggleAddressFileDialog()" :disabled="!signers_less">
                            {{ $t( "signflow.AddSignerGroup" ) }}
                          </v-btn>
                        </span>
                    </template>
                    <span v-if="!signers_less_exact"> {{ $t( 'signflow.MaxSigners' ) }} {{ max_signers}}.
                            {{ $t( 'signflow.MaxSigners1' ) }} {{ max_signers_diff }} {{ $t( 'signflow.MaxSigners2' ) }}
                    </span>
                    <span v-if="signers_less_exact && !signers_less"> {{ $t( 'signflow.MaxSigners' ) }} {{ max_signers}}. {{ $t( 'signflow.MaxSigners3' ) }}
                      </span>
                    </v-tooltip>
                  </v-col>
                  <v-col v-if="(flowType === 'me_and_others') || (flowType === 'only_others')" cols="auto" class="pa-3">
                    <v-tooltip bottom :disabled="signers_less">
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                          <v-btn class="alternative-primary-btn ml-auto" block @click="addSigner()" :disabled="!signers_ok || !signers_less">
                            {{ $t( "signflow.AddSigner" ) }}
                          </v-btn>
                        </span>
                      </template>
                      <span v-if="!signers_less_exact"> {{ $t( 'signflow.MaxSigners' ) }} {{ max_signers}}.
                            {{ $t( 'signflow.MaxSigners1' ) }} {{ max_signers_diff }} {{ $t( 'signflow.MaxSigners2' ) }}
                      </span>
                      <span v-if="signers_less_exact && !signers_less"> {{ $t( 'signflow.MaxSigners' ) }} {{ max_signers}}. {{ $t( 'signflow.MaxSigners3' ) }}
                      </span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
      <div class="mt-5">
        <v-row no-gutters>
          <h1 class="shio-section-title">{{ $t( "signflow.SignFlowDocumentsHeading" ) }}</h1>
        </v-row>
        <v-row no-gutters class="my-5">
          <v-col>
            <file-drag-and-drop @dropped="addDroppedFiles">
              <v-col cols="9" sm="12" class="ml-auto mr-auto">
                <input type="file" accept="application/pdf" multiple ref="fileUpload" hidden @change="sendFilesToQueue">
                <v-btn @click="fileUpload" class="mr-sm-6 shio-upload-button mb-6 mb-sm-0" depressed>
                  <v-icon class="mr-1">mdi-upload</v-icon>
                  {{ $t( "signflow.selectFromDisk" ) }}
                </v-btn>
                <google-file-picker @picked="addFromGoogle" @finished="uploadFiles">
                  <v-icon class="mr-1">mdi-google-drive</v-icon>
                  {{ $t( "signflow.GoogleDrive" ) }}
                </google-file-picker>
              </v-col>
            </file-drag-and-drop>
          </v-col>
        </v-row>
        <div v-if="documents.length > 0" class="shio-file-chips-container">
          <div v-for="doc in documents" :key="doc.$key">
            <v-chip outlined :color="`${doc.color}`" class="shio-file-chip ma-2" close label
                    @click:close="deleteDocument( doc )">
              <v-avatar left>
                <v-icon v-if="doc.complete">mdi-file-pdf-box</v-icon>
                <v-progress-circular size="24" :value="doc.progress" v-if="!doc.complete"/>
              </v-avatar>
              <a class="shio-file-download-link" v-text="doc.fileName" :href="makeTmpFileLink( doc)" target="_blank"
                 v-if="doc.token"></a>
              <span v-if="!doc.token">{{ doc.fileName }}</span>
              &nbsp;({{ doc.size_str }})
            </v-chip>
          </div>
          <div v-if="documents_to_upload.length" class="shio-file-chips-container">
            <div v-for="file in documents_to_upload">
              <v-chip outlined color="grey" class="shio-file-chip ma-2">
                {{ file.name }} ({{ fileSizeToStr( file.size) }})
              </v-chip>
            </div>
          </div>
        </div>
        <v-row v-if="documents.length > 0">
          <v-col>
            <v-text-field :rules="[ checkHyperlink ]"
                          :label="$t( 'signflow.FlowTitlePlaceholder' )"
                          v-model="flowTitle"></v-text-field>
          </v-col>
        </v-row>
      </div>
      <v-expansion-panels class="ma-0" v-model="extras" flat>
        <v-expansion-panel key="0" class="shio-expansion-panel">
          <v-expansion-panel-header class="pa-0 shio-expansion-panel-header">
            <h1 class="shio-section-title">{{ $t( "signflow.FlowSettings" ) }}</h1>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="shio-extras-content">
            <v-row cols="12" sm="4" class="my-0" no-gutters>
              <v-col class="pt-1 pb-2 hidden-sm-and-down">
                <v-checkbox :label="$t( 'signflow.UseDragAndDropSignature' )" hide-details class="shio-checkbox" :ripple="false"
                            v-model="use_drag_and_drop" @change="checkDragAndDrop()"/>
              </v-col>
              <v-col cols="12" sm="4" class="pt-1 pb-2">
                <v-checkbox :label="$t( 'signflow.NoPrintedEmail' )" hide-details class="shio-checkbox" :ripple="false"
                             v-model="hiddenEmail"/>
              </v-col>
              <v-col cols="12" sm="4" class="pt-1 pb-2" :class="{ invisible: flowType === 'only_me' }">
                <v-checkbox :label="$t( 'signflow.EditInvitation')" hide-details @change="resetTemplate()" class="shio-checkbox" :ripple="false" v-model="choose_signature"/>
              </v-col>
            </v-row>
            <v-container v-if="choose_signature && flowType !== 'only_me'">
            <v-row class="py-0 mt-2 mb-3" v-if="choose_signature && flowType !== 'only_me'" no-gutters>
              <v-col v-if="templatesExist && choose_signature && flowType !== 'only_me'" class="py-0 my-3"  cols="12" md="6">
                <v-autocomplete
                        v-model="chose_template_name"
                        @change="FillTemplate()"
                        :items="template_list"
                        :item-text="item => `${item.nameTemplate}`"
                        outlined
                        dense
                        hide-details
                        :label="$t( 'signflow.SavedTemplates' )"
                        :class="{ invisible: !templatesExist }"
                ></v-autocomplete>
              </v-col>
              <v-col v-if="!templatesExist && choose_signature && flowType !== 'only_me'" class="py-0 my-0"  cols="12" md="10">
                <p class="text-left custom-text-text">{{ $t( "signflow.ExplainTemplate1" ) }}</p>
                <p class="text-left custom-pink-text">{{ $t( "signflow.ExplainTemplate2" ) }}<span style="color:grey;">{{ $t( "signflow.ExplainTemplate3" ) }}</span></p>
              </v-col>
            </v-row>
            <v-row class="py-0 mt-2 mb-0">
              <v-col cols="12" md="7" class="py-0 my-0">
                <v-text-field
                        :label="$t( 'signflow.EmailSubject' )"
                        @keydown="onTemplateChange()"
                        :rules="[ checkHyperlink ]"
                        v-model="emailSubject"
                >
                </v-text-field>
              </v-col>
            </v-row>
              <v-row class="py-0 my-0">
                <v-col cols="12" md="10" class="py-0 my-0">
                <v-textarea
                        :label="$t( 'signflow.EmailMessage' )"
                        :rules="[ checkHyperlink ]"
                        v-model="emailMessage"
                        @keydown="onTemplateChange()"
                ></v-textarea>
              </v-col>
              </v-row>
              <v-row class="my-4" v-if="choose_signature && flowType !== 'only_me'" no-gutters>
                <v-col v-if="nameTemplate || ( !isTemplateOrig && templateChanged )"  class="pa-2" cols="12" md="2">
                  <v-btn v-if="nameTemplate || ( !isTemplateOrig && templateChanged )" class="secondary-btn"  @click="resetTemplate()">
                    {{ $t( "signedin.Reset" ) }}
                  </v-btn>
                </v-col>
                <v-col v-if="nameTemplate && ( isTemplateOrig || !templateChanged )" class="hidden-sm-and-down" cols="5">
                  <v-spacer/>
                </v-col>
                <v-col v-if="templateChanged && !isTemplateOrig" class="hidden-sm-and-down" cols="5">
                  <v-spacer/>
                </v-col>
                  <v-col v-if="templateChanged && !isTemplateOrig" class="pa-2" cols="12" md="2">
                    <v-btn v-if="templateChanged && !isTemplateOrig" class="primary-btn confirm-btn"  @click="updateEmailTemplate()">
                      {{ $t( "signedin.Save" ) }}
                    </v-btn>
                  </v-col>
                  <v-col v-if="nameTemplate && ( isTemplateOrig || !templateChanged )" class="pa-2" cols="12" md="2">
                    <v-btn v-if="nameTemplate && ( isTemplateOrig || !templateChanged )" class="secondary-btn" @click="deleteEmailTemplate()">
                      {{ $t( "signedin.Delete" ) }}
                    </v-btn>
                  </v-col>
                <v-col v-if="nameTemplate || ( !isTemplateOrig && templateChanged )" class="hidden-sm-and-down" cols="2">
                  <v-spacer/>
                </v-col>
                <v-col v-if="!nameTemplate && ( !templateChanged || isTemplateOrig )" cols="12" md="10" class="pb-2">
                    <p class="text-left custom-text-text">{{ $t( "signflow.ExplainTemplate4" ) }}
                    </p>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <p></p>
      <v-row no-gutters>
        <v-col cols="12" sm="6" md="4" class="pa-2">
          <v-btn class="secondary-btn" block @click="reset">{{ $t( "signflow.Reset" ) }}</v-btn>
        </v-col>
        <v-col class="hidden-sm-and-down" cols="4">
          <v-spacer/>
        </v-col>
        <v-col cols="12" sm="6" md="4" class="pa-2 text-right">
          <v-tooltip bottom :disabled="signers_less_exact">
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <v-btn class="primary-btn ml-auto" v-if="!use_drag_and_drop" block @click="preCreateSignFlow()"
                       :disabled="!ready_to_send">
                  <span v-if="flowType === 'only_me'">{{ $t( "signflow.GoToSign" ) }}</span>
                  <span v-if="flowType !== 'only_me'">{{ $t( "signflow.Send" ) }}</span>
                </v-btn>
                <v-btn class="primary-btn ml-auto" v-if="use_drag_and_drop" block @click="setSignaturePositions()"
                       :disabled="!ready_to_send">
                  {{ $t( "signflow.ButtonNext" ) }}
                </v-btn>
              </span>
            </template>
            <span> {{ $t( 'signflow.MaxSigners' ) }} {{ max_signers}}.
                   {{ $t( 'signflow.MaxSigners1' ) }} {{ max_signers_diff }} {{ $t( 'signflow.MaxSigners2' ) }}
            </span>
          </v-tooltip>
          <p v-if="walletEnabled && ( signatureLevel === 'advanced' ) && signers?.length > 0 && this.subscription?.address?.country ==='FI'" class="mt-1">
            ({{$t( 'wallet.EstimatedAdvancedSignatureCost' ) }}{{ ( signflow_estimated_cost / 100 ).toFixed( 2 ) }} + VAT)
          </p>
          <p v-if="walletEnabled && ( signatureLevel === 'advanced' ) && signers?.length > 0 && this.subscription?.address?.country !=='FI'" class="mt-1">
            ({{$t( 'wallet.EstimatedAdvancedSignatureCost' ) }}{{ ( signflow_estimated_cost / 100 ).toFixed( 2 ) }})
          </p>
        </v-col>
      </v-row>
    </div>
    <v-dialog v-model="address_file_dialog" width="auto ">
      <v-card>
        <v-card-title>
          <b>{{ $t( "signflow.AddSignerGroup")}}</b>
          <v-spacer/>
          <v-icon @click="toggleAddressFileDialog()">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <p>
            <v-file-input outlined dense accept=".txt,.csv" v-model="address_file" @change="addSignersFromFile()"
                          :label="$t( 'signflow.AddressBookChooseFile' )"/>
          </p>
          <v-divider class="ma-2"/>
          <div class="text-left">
            <h3 class="text-center pb-2">{{ $t( "signflow.AddressBookInfoHeader" ) }}</h3>
            <p>{{ $t( "signflow.AddressBookInfo" ) }}</p>
            <p>{{ $t( "signflow.AddressBookInfoExampleHeader" ) }}</p>
            <p v-html="$t( 'signflow.AddressBookInfoExampleContent' )"></p>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="signature_types_dialog" width="auto">
      <v-card>
        <v-card-title>
          <b>{{ $t( 'signflow.SelectSignatureTypes' ) }}</b>
          <v-spacer></v-spacer>
          <v-btn icon @click="toggleSignatureTypesDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text style="min-height: 50vh;">
          <v-radio-group v-model="signature_level" @change="signatureLevelOnchange()" class="signature-types-dialog" >
            <v-row no-gutters>
              <v-col cols="12" sm="6" v-if="this.enabledLevels.indexOf( 'basic' ) !== -1 ">
                <v-radio value="basic" :label="$t( 'signflow.BasicLevel' )"/>
              </v-col>
              <v-col cols="12" sm="6" v-if="this.enabledLevels.indexOf( 'advanced' ) !== -1 ">
                <v-radio value="advanced" :label="$t( 'signflow.AdvancedLevel' )"/>
              </v-col>
            </v-row>
          </v-radio-group>
          <v-divider class="ma-2"/>
          <div v-if="signature_level === 'basic'">
            <div class="text-left ma-2">
              <p class="basic-level-info">{{ $t( "signflow.BasicLevelInfo" ) }}</p>
            </div>
          </div>
          <div v-if="signature_level === 'advanced'" class="text-left">
            <div class="text-left ma-2">
              <p class="advanced-level-info">{{ $t( "signflow.AdvancedLevelInfo" ) }}</p>
            </div>
            <v-row v-for="signature_type in enabled_advanced_signature_types">
              <v-col cols="12">
                <v-checkbox class="ma-0" v-model="selected_signature_types" :value="signature_type" @change="">
                  <template v-slot:label>
                    {{ $t("signedin.SignatureType!" + signature_type ) }}
                  </template>
                </v-checkbox>
              </v-col>
            </v-row>
          </div>
          <div class="text-right apply-signature-types-btn">
            <button @click.prevent="">
              <v-btn block @click="saveSignatureTypes()"
                     :disabled="signature_level === 'advanced' && this.selected_signature_types?.length === 0 "
                     class="primary-btn py-5 ma-auto">{{ $t( 'signflow.Apply' ) }}
              </v-btn>
            </button>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-overlay v-if="is_working">
      <v-progress-circular size="48" indeterminate/>
    </v-overlay>
  </v-card>
</template>

<script>
    import util from "../../../../util/util";
    import axios from "axios";
    import { globalEvents } from "@cloudlace/client";
    import { timeout } from "@cloudlace/client";
    import flowMixin from "./flow-mixin";
    import GoogleFilePicker from "./GoogleFilePicker.vue";
    import FileDragAndDrop from "./FileDragAndDrop.vue";
    // TODO: verify that these constants are correct
    const MAX_UPLOAD_SIZE = 31457280;
    const MAX_TOTAL_UPLOADS = MAX_UPLOAD_SIZE * 2;
    const MAX_TOTAL_FILES_NUMBER = 20;
    const MAX_SIGNERS = 80;
    const advancedSignaturePrice = process.env.VUE_APP_WALLET_PRICE ? JSON.parse( process.env.VUE_APP_WALLET_PRICE ) : [];
    const locales = [ "en", "fi", "sv", "pl", "de", "nl", "fr", "es", "th" ];
    let onServerMessage, keygen = 0;
    let currentDocsLength = 0;
    export default {
        name : "start-flow",
        components : {
            GoogleFilePicker,
            FileDragAndDrop
        },
        mixins : [ flowMixin ],
        data()
        {
            return {
                extras : [],
                is_working : false,
                use_drag_and_drop : false,
                hiddenEmail: false,
                choose_signature : false,
                choose_invitation_template : false,
                address_file : undefined,
                address_file_dialog : false,
                documents_to_upload : [],
                flowType : "noflow",
                flowTitle : "",
                documents : [],
                signers : [],
                signers_hidden : [],
                orgUsers : [],
                emailSubject : eval( "`" + this.$t( "signflow.DefaultEmailSubject" ) + "`" ),
                emailMessage : eval( "`" + this.$t( "signflow.DefaultEmailMessage" ) + "`" ),
                subscription : $cc_user_info.subscription,
                stripePaymentOn : $cc_user_info.stripePaymentOn,
                walletEnabled : $cc_user_info.organization.wallet_enabled,
                is_subscription_admin : util.getIsSubscriptionAdmin(),
                chose_template_name : "",
                template_list : [],
                templateId : "",
                nameTemplate : "",
                delete_template_name : "",
                templateChanged : false,
                original_template : {},
                foundTemplateData : {},
                templatesExist : false,
                signatureLevel : "",
                enabledLevels : [],
                signature_types_dialog : false,
                enabled_advanced_signature_types : [],
                selected_signature_types : [],
                current_me_signature_types : [],
                signature_level : "",
                previousOnlyMeSignatureTypes : []
            };
        },
        /**
         * Map this.items to state.notes through the Vuex mapState utility.
         */
        computed : {
            computedWidth() {
                return `${window.innerWidth * 0.7}px`;
            },
            computedHeight() {
                return `${window.innerHeight * 0.7}px`;
            },
            ready_to_send()
            {
                return this.signers_ok  && this.uploads_ok && this.email_ok && this.documents.length > 0 && this.signers_less_exact;
            },
            email_ok()
            {
                return ( this.checkHyperlink( this.emailSubject ) === true )
                       && ( this.checkHyperlink( this.emailMessage ) === true );
            },
            uploads_ok()
            {
                return this.uploadsOk( true ) === true;
            },
            signers_ok()
            {
                for( let i = this.signers.length - 1; i >= 0; i-- )
                {
                    if( !this.signers[ i ].valid || !this.signers[ i ].signerEmail || !this.signers[ i ].signerName
                        || ( ( this.signatureLevel === "advanced" ) && this.signers[ i ].allowedSignatureTypes && this.signers[ i ].allowedSignatureTypes.length === 0 ) )
                    {
                        return false;
                    }
                }
                return ( this.signers.length > 0 );
            },
            signers_less_exact()
            {
                return ( this.signers.length <= MAX_SIGNERS );
            },
            signers_less()
            {
                return (  this.signers.length < MAX_SIGNERS );
            },
            max_signers()
            {
                return ( MAX_SIGNERS );
            },
            max_signers_diff()
            {
                return ( this.signers.length - MAX_SIGNERS );
            },
            language_options()
            {
                return locales.map( locale =>
                {
                    return {
                        locale,
                        language : this.$t( "signedin.locale!" + locale )
                    }
                } );
            },
            isTemplateOrig()
            {
                return ((this.emailSubject === this.original_template.emailSubject)
                        && (this.emailMessage === this.original_template.emailMessage));
            },
            localizedSignatureTypes()
            {
                return this.enabled_advanced_signature_types.map( signature_type => ({
                    value : signature_type,
                    text : this.$t( "signedin.SignatureType!" + signature_type )
                }) );
            },
            localizedOnlyMeSignatureTypes()
            {
                const all_signature_types = this.enabled_advanced_signature_types?.slice();
                if( this.enabledLevels.indexOf( "basic" ) !== -1 )
                {
                    all_signature_types.unshift( "email-based-signature" );
                }
                return all_signature_types.map( signature_type => ({
                    value : signature_type,
                    text : this.$t( "signedin.SignatureType!" + signature_type )
                }) );
            },
            showOnlyMeSignatureSelector()
            {
                if( this.flowType !== 'only_me' )
                {
                    return false;
                }
                else
                {
                    if( this.enabled_advanced_signature_types?.length === 0 )
                    {
                        return false;
                    }
                    else
                    {
                        return true;
                    }
                }
            },
            signflow_estimated_cost()
            {
                if( this.signatureLevel === "advanced" )
                {
                    let mostCost = 0;
                    this.signers.forEach( signer => {
                        mostCost += this._getMostExpensivePrice( signer.allowedSignatureTypes );
                    });
                    return mostCost;
                }
            }
        },
        methods : {
            /**
             * Hides subscription time banner.
             */
            hideBanner()
            {
                $cc_user_info.subscription.hideBanner = true;
            },
            /**
             * Store info about the sign flow being created in .$store so the place-signatures pane can read it and we
             * can return here from there with the back button, then navigate to /place-signatures.
             */
            setSignaturePositions()
            {
                this.$store.flow_to_create = {
                    extras : this.extras,
                    use_drag_and_drop : this.use_drag_and_drop,
                    choose_signature : this.choose_signature,
                    flowType : this.flowType,
                    flowTitle : this.flowTitle,
                    documents : this.documents,
                    signers : this.signers,
                    signers_hidden : this.signers_hidden,
                    emailSubject : this.emailSubject,
                    emailMessage : this.emailMessage,
                    signatureLevel : this.signatureLevel,
                    hiddenEmail : this.hiddenEmail
                };
                this.$router.push( "/place-signatures" );
            },
            /**
             * Remove doc from array.
             *
             * @param doc {Object}
             */
            deleteDocument( doc )
            {
                this.documents.splice( this.documents.indexOf( doc ), 1 );
            },
            /**
             * Toggle add from address book dialog.
             */
            toggleAddressFileDialog()
            {
                this.address_file_dialog = !this.address_file_dialog;
            },
            /**
             * Toggle set signature types dialog.
             */
            toggleSignatureTypesDialog()
            {
                this.signature_level = this.signatureLevel;
                this.signature_types_dialog = !this.signature_types_dialog;
            },
            /**
             * Toggle if emails should be printed.

            toggleNoPrintedEmail()
            {
                this.hiddenEmail = !this.hiddenEmail;
            },*/
            /**
             * Email validator.
             */
            validateEmail : util.validateEmail,
            /**
             * Full name validator.
             */
            validateFullName : util.validateFullName,
            /**
             * Hyperlink checker
             */
            checkHyperlink : util.checkHyperlink,
            /**
             * Signer name validator.
             */
            validateSignerName( value )
            {
                return( !value || util.goodRegExp.test( value ) || this.$t( "message.EnterValidCharacters" ) );
            },
            validateAdvancedMethods( value )
            {
                return( this.signatureLevel === "advanced" && value.length > 0 ) || "Add least one advanced signature type";
            },
            /**
             * Get a new locally unique key.
             */
            key()
            {
                return keygen++;
            },
            /**
             * Click the hidden file input button
             */
            fileUpload()
            {
                this.$refs.fileUpload.click();
            },
            /**
             *  If user may upload more, add the the files from the hidden file input to the queue and start the upload process
             */
            sendFilesToQueue( event )
            {
                if( this._mayUploadMore( event.target.files ) )
                {
                    const queueIsEmpty = !this.documents_to_upload.length;
                    this.documents_to_upload.push( ...event.target.files );
                    this.$refs.fileUpload.value = '';
                    if ( queueIsEmpty )
                    {
                        this.uploadFiles();
                    }
                }
            },
            /**
             * If user may upload more, add the the files from file-drag-and-drop component to the queue and start the upload process
             * @param files {File[]}
             */
            addDroppedFiles( files )
            {
                if( this._mayUploadMore( files ) )
                {
                    const queueIsEmpty = !this.documents_to_upload.length;
                    this.documents_to_upload.push( ...files );
                    if ( queueIsEmpty )
                    {
                        this.uploadFiles();
                    }
                }
            },
            /**
             * Upload the next file and report result.
             */
            uploadFiles()
            {
                currentDocsLength = this.documents.length;
                this._uploadNext().then( r =>
                {
                    const uploadsOk = this.uploadsOk();
                    if( uploadsOk !== true )
                    {
                        this.$error( uploadsOk );
                    }
                } ).catch( e =>
                {
                } );
            },
            /**
             * If no uploads have failed, return true, else return the first upload error.
             * @param fromTheStart {boolean}
             */
            uploadsOk( fromTheStart = false )
            {
                const fails = this.documents.slice( fromTheStart ? 0 : currentDocsLength ).filter( doc => doc.failed === true || doc.complete === false );
                if( fails.length === 0 )
                {
                    return true;
                }
                else
                {
                    return fails[ 0 ].error || this.$t( "signflow.uploadError" );
                }
            },
            /**
             * Set the flow type. This determines the UI state. It is not stored with the flow however.
             */
            setFlowType()
            {
                // allow stack to clear
                setTimeout( () =>
                {
                    if( this.signers.length !== 0 )
                    {
                        if( this.signers[ 0 ].signerType === "me" )
                        {
                            this.current_me_signature_types = this.signers[ 0 ].allowedSignatureTypes;
                            this.signers.shift();
                        }
                        if( this.signers.length !== 0 )
                        {
                            this.signers_hidden = [...this.signers];
                        }
                    }
                    switch( this.flowType )
                    {
                        case "only_me" :
                            this.signers = [ this._getSigner( $cc_user_info.email, $cc_user_info.name, "me", this.current_me_signature_types ) ];
                            break;

                        case "me_and_others" :
                            if( this.signers_hidden.length === 0 )
                            {
                                this.signers = [ this._getSigner( $cc_user_info.email, $cc_user_info.name, "me", this.current_me_signature_types ),
                                                this._getSigner( "", "", "others", [] ) ];
                            }
                            else
                            {
                                this.signers = [...this.signers_hidden];
                                this.signers.unshift( this._getSigner( $cc_user_info.email, $cc_user_info.name, "me", this.current_me_signature_types ) );
                            }
                            break;

                        case "only_others" :
                            if( this.signers_hidden.length === 0 )
                            {
                                this.signers = [this._getSigner( "", "", "others", [] )];
                            }
                            else
                            {
                                this.signers = [...this.signers_hidden];
                            }
                            break;
                        default :
                    }
                }, 1 );
            },
            /**
             * Add an empty signer card.
             */
            addSigner()
            {
                this.signers.push( this._getSigner( "", "" ) );
            },
            /**
             * Add signers from CSV file.
             */
            addSignersFromFile()
            {
                const sfRegExp = /^([^,@]+,\s*[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*\s*\n*)+$/;
                const fr = new FileReader();
                fr.onload = () =>
                {
                    const signers = fr.result;
                    if( !sfRegExp.test( signers ) )
                    {
                        this.address_file = undefined;
                        this.$warn( this.$t( "signflow.NotAnAddressBook" ) );
                    }
                    else
                    {
                        const lines = signers.split( "\n" );
                        const _signers = [];
                        let invalidChars = false;
                        lines.forEach( line =>
                        {
                            const signer = line.split( "," );
                            if( signer[ 0 ] && signer[ 1 ] && !this._signerExists( signer[ 1 ] ) )
                            {
                                if( !util.goodRegExp.test( signer[ 0 ] ) )
                                {
                                    invalidChars = true;
                                }
                                _signers.push( this._getSigner( signer[ 1 ].trim(), signer[ 0 ].trim() ) );
                            }
                        } );
                        this.signers = this.signers.concat( _signers );
                        const oneSignerIsAddedMe = this.signers.some( signer => this._signerIsMe( signer.signerEmail )
                                                                                 && signer.signerType !== "me" );
                        this.signers =
                            this.signers.filter( signer => !!signer.signerEmail
                                                           && !!signer.signerName
                                                           && !( this._signerIsMe( signer.signerEmail) && signer.signerType !== "me" ) );
                        // remove duplicated emails from the signers, leave only one copy of each email
                        this.signers = this.signers.filter( ( signer, index ) =>
                            this.signers.findIndex( item => item.signerEmail === signer.signerEmail ) === index );
                        this.address_file = undefined;
                        this.address_file_dialog = false;
                        if ( oneSignerIsAddedMe && this.flowType === "only_others" )
                        {
                            if ( !this.signers.length )
                            {
                                this.addSigner();
                            }
                            this.flowType = "me_and_others";
                            this.setFlowType();
                        }
                        if( invalidChars )
                        {
                            this.$warn( this.$t( "signflow.CheckFileEncoding" ) );
                        }
                    }
                };
                if( this.address_file )
                {
                    try
                    {
                        fr.readAsText( this.address_file );
                    } catch( e )
                    {
                        this.$warn( this.$t( "signflow.NotAnAddressBook" ) );
                    }
                }
            },
            /**
             * Remove signer from signers array.
             * @param signer {object}
             * @param index {number}
             */
            removeSigner( signer, index )
            {
                this.signers.splice( index, 1 );
                this.signers_hidden.splice( this.signers_hidden.indexOf( signer ), 1 );
                if( this.signers.length === 0 )
                {
                    this.reset();
                }
                else
                {
                    if( signer.signerType === "me" )
                    {
                        this.flowType = "only_others";
                    }
                    else
                    {
                        if( ( this.flowType === "me_and_others" ) && ( this.signers.length === 1 ) )
                        {
                            this.flowType = "only_me";
                        }
                    }
                }
            },
            /**
             * Check if another user's email from the organisation was added and if so, set name.
             * Check if "only others" signer is in fact "me" and if so, switch to "me_and_others" flow.
             * @param signer {object}
             * @param index {number}
             */
            checkSignerEmail( signer, index )
            {
                const users = this.orgUsers.filter( user => user.email.trim().toLowerCase() === signer.signerEmail.trim().toLowerCase() );
                if( users.length )
                {
                    signer.signerName = users[ 0 ].name;
                }
                if ( this.flowType === "only_others" && this._signerIsMe( signer.signerEmail ) )
                {
                    this.signers.splice( index, 1 );
                    if ( !this.signers.length )
                    {
                        this.addSigner();
                    }
                    this.flowType = "me_and_others";
                    this.setFlowType();
                }
            },
            /**
             * Removes zero-width-space.
             *
             * @param signer {object}
             * @param index {number}
             */
            removeZeroSpace( signer, index )
            {
                this.signers[ index ].signerEmail = this.signers[ index ].signerEmail.replace( /[\u200B-\u200F\uFEFF]/g, '' );
            },
            /**
             * Clear preplaced signatures if unchecking drag_and_drop
             */
            checkDragAndDrop()
            {
                 if ( !this.use_drag_and_drop )
                 {
                     for( let i = 0; i < this.documents.length; i++ )
                     {
                         if ( this.documents[ i ].signatures )
                         {
                             delete this.documents[ i ].signatures;
                         }
                         this.documents[i].signatures_placed = false;
                     }
                 }
            },
            /**
             * Unset is_working and reset form.
             */
            terminate( err )
            {
                this.is_working = false;
                this.reset();
            },
            /**
             * Reset form to default state.
             */
            reset()
            {
                this.extras = [];
                this.is_working = false;
                this.processUuid = undefined;
                this.use_drag_and_drop = false;
                this.choose_signature = false;
                this.choose_invitation_template = false;
                this.documents_to_upload = [];
                this.documents = [];
                this.signers = [];
                this.signers_hidden = [];
                this.flowTitle = "";
                this.emailSubject = eval( "`" + this.$t( "signflow.DefaultEmailSubject" ) + "`" );
                this.emailMessage = eval( "`" + this.$t( "signflow.DefaultEmailMessage" ) + "`" );
                this.flowType = "noflow";
                delete this.$store.flow_to_create;
                this.resetTemplate();
            },
            /**
             * If no signer matching email is present, return true.
             *
             * @returns {boolean}
             */
            signerIsNew( email )
            {
                return !this._signerExists( email, true ) || this.$t( "message.MustBeUniqueEmail" );
            },
            /**
             * If user may upload more, add file from Google Drive to the list
             */
            addFromGoogle( data )
            {
                const addedFile = new File( [ data.blob ], data.name, { type: data.blob.type } );
                if( this._mayUploadMore( [ addedFile ] ) )
                {
                    this.documents_to_upload.push( addedFile );
                }
            },
            /**
             * Make a link for downloading a temporary file from SHKit
             */
            makeTmpFileLink( doc )
            {
                return process.env.VUE_APP_SIGNFLOW_DOWNLOAD_TEMPORARY_URL + `?token=${ doc.token }`;
            },
            /**
             * Convert file size to string
             * @param size {number}
             * @returns {string}
             */
            fileSizeToStr( size )
            {
                return Math.floor( size / 1024 ) + " kB";
            },
            /**
             * Apply advanced methods to all signers.
             */
            applyAdvancedMethods()
            {
                this.signers.map( signer =>{
                    signer.allowedSignatureTypes = this.selected_signature_types;
                })
                if( this.selected_signature_types && this.selected_signature_types.length > 0 )
                {
                    this.signatureLevel = "advanced";
                }
                this.signature_types_dialog = false;
            },
            /**
             * Signature level changed.
             */
            signatureLevelOnchange()
            {
                if( this.signature_level === 'advanced' && this.enabled_advanced_signature_types.length === 1 )
                {
                    this.selected_signature_types = this.enabled_advanced_signature_types;
                }
            },
            /**
             * Save signature types.
             */
            saveSignatureTypes()
            {
                if( this.signature_level === "basic" )
                {
                    this.signers.map( signer =>
                    {
                        signer.allowedSignatureTypes = ["email-based-signature"];
                    } );
                }
                else
                {
                    this.signers.map( signer =>
                    {
                        signer.allowedSignatureTypes = this.selected_signature_types;
                    } );
                }
                this.signatureLevel = this.signature_level;
                this.signature_types_dialog = false;
            },
            /**
             * On only me signature types changed.
             *
             * @param newItems
             */
            onOnlyMeSignatureTypesSelect( newItems )
            {
                let lastChangedItem = null;
                if( newItems.length > this.previousOnlyMeSignatureTypes.length )
                {
                    const addedItems = newItems.filter( item => !this.previousOnlyMeSignatureTypes.includes( item ) );
                    lastChangedItem = addedItems[ 0 ];
                    if( lastChangedItem === "email-based-signature" )
                    {
                        this.signers[ 0 ].allowedSignatureTypes = ["email-based-signature"];
                        this.signatureLevel = "basic";
                    }
                    else
                    {
                        this.signers[ 0 ].allowedSignatureTypes =
                        newItems.filter( item => item !== "email-based-signature" );
                        this.signatureLevel = "advanced";
                    }
                    this.previousOnlyMeSignatureTypes = this.signers[ 0 ].allowedSignatureTypes;
                }
            },
            /**
             * If a signer matching email is present, return true, unless old is set, in which case return true if there
             * is more than 1 matching signer.
             *
             * @returns {boolean}
             */
            _signerExists( email, old )
            {
                if( !email )
                {
                    return false;
                }
                else
                {
                    const mails = this.signers.filter( signer => signer.signerEmail.trim().toLowerCase()
                                                                 === email.trim().toLowerCase() );
                    return ( old ? mails.length > 1 : mails.length > 0 );
                }
            },
            /**
             * Checks if the given email belongs to current user
             * @param email {string}
             * @return {boolean}
             */
            _signerIsMe( email )
            {
                return ( email.trim().toLowerCase() === $cc_user_info.email.trim().toLowerCase() );
            },
            /**
             * Return a new signer with email, name, and type.
             *
             * @param email {string}
             * @param name {string}
             * @param type {string} - "me"|"others"
             * @returns {Object}
             */
            _getSigner( email, name, type, signature_types )
            {
                const signer = {
                    $key : this.key(),
                    signerName : name,
                    signerEmail : email,
                    signerType : type || "others",
                    locale : util.getLocale(),
                    allowedSignatureTypes : (!signature_types || signature_types.length === 0)
                    ? this.selected_signature_types : signature_types,
                };
                if( type === "me" )
                {
                    signer.valid = true;
                }
                if( this.flowType === "only_me" )
                {
                    if( signer.allowedSignatureTypes.length === 0 )
                    {
                        signer.allowedSignatureTypes = ["email-based-signature"];
                    }
                    this.previousOnlyMeSignatureTypes = signer.allowedSignatureTypes;
                }
                if( this.signatureLevel === "advanced" && signer.allowedSignatureTypes.length === 0 && this.enabled_advanced_signature_types.length === 1 )
                {
                    signer.allowedSignatureTypes = this.enabled_advanced_signature_types;
                }
                return signer;
            },
            /**
             * If user is below total upload limit, return true.
             *
             * @param files {File[]}
             * @returns {boolean}
             */
            _mayUploadMore( files )
            {
                let uploadedSize = 0;
                for( let i = 0; i < files.length; i++ )
                {
                    uploadedSize += files[ i ].size;
                }
                this.documents_to_upload.forEach( doc => uploadedSize += doc.size );
                this.documents.forEach( doc => uploadedSize += doc.size );
                if( uploadedSize > MAX_TOTAL_UPLOADS )
                {
                    const maxSize = Math.round( MAX_TOTAL_UPLOADS / 1024 / 1024 ) + " MB";
                    uploadedSize = Math.round( uploadedSize / 1024 / 1024 ) + " MB";
                    this.$error( eval( "`" + this.$t( "signflow.allFilesTooLarge" ) + "`" ) );
                    return false;
                }
                if( ( files.length + this.documents_to_upload.length + this.documents.length ) > MAX_TOTAL_FILES_NUMBER )
                {
                    const maxFileNumber = MAX_TOTAL_FILES_NUMBER;
                    this.$error( eval( '`' + this.$t( "signflow.fileNumberTooLarge" ) + '`' ) );
                    return false;
                }
                return true;
            },
            /**
             * Upload next file selected by user.
             *
             * @returns {Promise}
             */
            _uploadNext()
            {
                return new Promise( ( resolve, reject ) =>
                {
                    const file = this.documents_to_upload.shift();
                    if( !file )
                    {
                        resolve();
                    }
                    else
                    {
                        const fileSize = this.fileSizeToStr( file.size );
                        const doc = {
                            $key : this.key(),
                            color : "gray",
                            progress : 0,
                            complete : false,
                            size_str : fileSize,
                            size : file.size,
                            fileName : file.name
                        };
                        if( file.size > MAX_UPLOAD_SIZE )
                        {
                            const maxSize = Math.round( MAX_UPLOAD_SIZE / 1024 / 1024 ) + " MB";
                            doc.failed = true;
                            this.$error( eval( "`" + this.$t( "signflow.fileTooLarge" ) + "`" ) );
                            this.documents_to_upload = [];
                            resolve();
                        }
                        else
                        {
                            this.documents.push( doc );
                            this._doUpload( file, doc ).then( r =>
                            {
                                if( this.documents_to_upload.length > 0 )
                                {
                                    this._uploadNext().then( () => resolve() ).catch( e => reject() );
                                }
                                else
                                {
                                    resolve();
                                }
                            } ).catch( e =>
                            {
                                if( this.documents_to_upload.length > 0 )
                                {
                                    this._uploadNext().then( () => resolve() ).catch( e => reject() );
                                }
                                else
                                {
                                    resolve();
                                }
                            } );
                        }
                    }
                } );
            },
            /**
             * Upload file to match doc; if it fails, retry once.
             *
             * @param file {File}
             * @param doc {Object}
             * @param isRetry {boolean?}
             * @returns {Promise}
             */
            _doUpload( file, doc, isRetry )
            {
                return new Promise( ( resolve, reject ) =>
                {
                    axios( {
                        url : process.env.VUE_APP_SIGNFLOW_UPLOAD_URL + "/" + file.name,
                        method : "POST",
                        data : file,
                        withCredentials : true,
                        xsrfCookieName : "XSRF-TOKEN",
                        xsrfHeaderName : "X-XSRF-TOKEN",
                        headers : {
                            "content-type" : "application/pdf"
                        },
                        onUploadProgress : ( evt =>
                        {
                            doc.progress = evt.total / evt.loaded;
                        } ),
                        timeout : 30000
                    } ).then( r =>
                    {
                        if( r.data.action_str === "CREATED" )
                        {
                            doc.token = r.data.response_map.token;
                            doc.complete = true;
                            doc.color = "green";
                            doc.file = file;
                        }
                        else
                        {
                            doc.complete = true;
                            doc.failed = true;
                            doc.color = "red";
                            doc.error = this.$t( "signflow.uploadError" );
                            doc.file = file;
                        }
                        resolve( r.data );
                    } ).catch( e =>
                    {
                        if( !isRetry )
                        {
                            this._doUpload( file, doc, true ).then( r => resolve( r ) ).catch( e => reject( e ) );
                        }
                        else
                        {
                            doc.complete = true;
                            doc.failed = true;
                            doc.color = "red";
                            reject( e );
                        }
                    } );
                } );
            },
            _prefill( prefillData )
            {
                this.extras = prefillData.extras;
                this.use_drag_and_drop = prefillData.use_drag_and_drop;
                this.choose_signature = prefillData.choose_signature;
                this.documents = prefillData.documents;
                this.flowType = prefillData.flowType;
                this.flowTitle = prefillData.flowTitle;
                this.signers = prefillData.signers;
                this.signers_hidden = prefillData.signers_hidden;
                this.emailSubject = prefillData.emailSubject;
                this.emailMessage = prefillData.emailMessage;
                this.signatureLevel = prefillData.signatureLevel;
                this.hiddenEmail = prefillData.hiddenEmail;
            },
            /**
             *  Extracts signflow params for prefilling.
             *
             * @param signFlow {Object}
             * @returns {signFlowParams}
             */
            _getSignFlowParams( signFlow )
            {
                let signFlowParams = {};
                let hasMe = false;
                let hasOthers = false;
                signFlowParams.signers_hidden = [];
                signFlowParams.documents = [];
                signFlowParams.signers = signFlow.signers;
                signFlowParams.emailMessage = signFlow.emailMessage;
                signFlowParams.emailSubject = signFlow.emailSubject;
                signFlowParams.signatureLevel = signFlow.signatureLevel;
                signFlowParams.hiddenEmail = signFlow.hiddenEmail;
                signFlowParams.signers.forEach( signer =>
                {
                    signFlow.signEvents.forEach( signEvent =>
                    {
                        if( signer.signerEmail === $cc_user_info.email )
                        {
                            signer.signerType = "me";
                            signer.signerName = $cc_user_info.name;
                            signer.locale = util.getLocale();
                            hasMe = true;
                            let index = signFlowParams.signers.indexOf( signer );
                            if ( index !== 0 )
                            {
                              const element = signFlowParams.signers.splice( index, 1 )[ 0 ];
                              signFlowParams.signers.splice( 0, 0, element );
                            }
                            if( signer.signerEmail === signEvent.signerEmail )
                            {
                                signer.allowedSignatureTypes = signEvent.allowedSignatureTypes;
                            }
                        }
                        else
                        {
                            signer.signerType = "others";
                            if( signer.signerEmail === signEvent.signerEmail )
                            {
                                signer.locale = signEvent.locale;
                                signer.allowedSignatureTypes = signEvent.allowedSignatureTypes;
                            }
                            hasOthers = true;
                        }
                    });
                });
                if( hasMe && hasOthers )
                {
                    signFlowParams.flowType = "me_and_others";
                }
                else if( hasMe && !hasOthers )
                {
                    signFlowParams.flowType = "only_me";
                }
                else if( !hasMe && hasOthers )
                {
                    signFlowParams.flowType = "only_others";
                }
                if( signFlow.emailMessage || signFlow.emailSubject )
                {
                    signFlowParams.choose_signature = true;
                    signFlowParams.extras = 0;
                }
                return signFlowParams;
            },
            /**
             * Get most expensive signature price for the signer.
             *
             * @param allowedSignatureTypes
             * @returns {number}
             * @private
             */
            _getMostExpensivePrice( allowedSignatureTypes )
            {
                let highestPrice = 0;
                allowedSignatureTypes.map( type =>
                {
                    if ( advancedSignaturePrice[ type ] > highestPrice )
                    {
                        highestPrice = advancedSignaturePrice[ type ];
                    }
                } );
                return highestPrice;
            },
            /**
             * Click reset button on custom invitation pane.
             */
            resetTemplate()
            {
                this.emailSubject = eval( "`" + this.$t( "signflow.DefaultEmailSubject" ) + "`" );
                this.original_template.emailSubject = this.emailSubject;
                this.emailMessage = eval( "`" + this.$t( "signflow.DefaultEmailMessage" ) + "`" );
                this.original_template.emailMessage = this.emailMessage;
                this.nameTemplate = "";
                this.original_template.nameTemplate = "";
                this.chose_template_name = "";
            },
            /**
             * Create the email invitation template.
             */
            createEmailTemplate()
            {
              const savedTemplateNames = this.template_list.map(templates => templates.nameTemplate);
              if (savedTemplateNames.includes(this.nameTemplate))
              {
                const templateList = this.template_list;
                const foundTemplateData = templateList.find( templates => templates.nameTemplate === this.nameTemplate );
                this.templateId = foundTemplateData.templateId;
                const text = this.$t("signflow.Error!TemplateNameExist");
                const acceptText = this.$t("signedin.Confirm");
                const cancelText = this.$t("signedin.Cancel");
                this.$confirm( {text, acceptText, cancelText} ).then( r =>
                        {
                          this.chose_template_name = this.nameTemplate;
                          this.doUpdateEmailTemplate();
                        }
                 ).catch( () =>
          {
            this.nameTemplate="";
            this.templateId="";

          } );
              }
              else
              {
                this.$store.signFlowClient.createEmailTemplate($cc_user_info.organization.uuid,
                        this.emailSubject,
                        this.emailMessage,
                        this.nameTemplate).then(
                        r =>
                        {
                          this.templateId = r.templateId;
                          this.nameTemplate = r.nameTemplate;
                          this.emailSubject = r.emailSubject;
                          this.emailMessage = r.emailMessage;
                          this.$warn({
                            text: this.$t( "signflow.TemplateCreated" )
                          });
                          this.addToTemplateList(this.nameTemplate,
                                  this.templateId,
                                  this.emailSubject,
                                  this.emailMessage);
                          this.chose_template_name = this.nameTemplate;
                          this.templateChanged = false;
                          this.templatesExist = true;
                        }
                ).catch(e =>
                {
                  this.$error(this.$t( "signflow.Error!Unexpected" ));
                });
              }
            },
            /**
             * Add the created template to the list.
             */
            addToTemplateList( nameTemplate, templateId, emailSubject, emailMessage )
            {
                const templateKey = {
                    nameTemplate : nameTemplate,
                    templateId : templateId,
                    emailSubject : emailSubject,
                    emailMessage : emailMessage
                };
                this.template_list.push( templateKey );
            },
            /**
             * Retrieve saved templates in the group.
             */
            retrieveEmailTemplates()
            {
                this.$store.signFlowClient.retrieveEmailTemplates( $cc_user_info.organization.uuid ).then(
                res =>
                {
                    this.template_list = res.templates;
                    if ( Object.keys(this.template_list).length !== 0 )
                    {
                         this.templatesExist = true;
                    }
                    else
                    {
                      this.templatesExist = false;
                    }
                });
            },
            /**
             * Delete an email invitation template.
             */
            deleteEmailTemplate()
            {
                const templateList = this.template_list;
                const foundDeleteTemplate = templateList.find( templates => templates.nameTemplate === this.nameTemplate );
                this.templateId = foundDeleteTemplate.templateId;
                const text = this.$t( "signflow.DeleteTemplateConfirm" );
                const acceptText = this.$t( "signflow.Confirm" );
                const cancelText = this.$t( "signflow.Cancel" );
                this.$confirm( { text, acceptText, cancelText } ).then( r =>
                {
                    this.$store.signFlowClient.deleteEmailTemplate( $cc_user_info.organization.uuid,
                    this.templateId ).then( r =>
                    {
                        this.template_list.splice( foundDeleteTemplate );
                        this.retrieveEmailTemplates();
                        this.chose_template_name = "";
                        this.delete_template_name = "";
                        this.resetTemplate();
                        this.$inform( this.$t( "signflow.DeleteTemplate" ) );
                    } ).catch( e =>
                    {
                        this.$error( this.$t( "signflow.Error!Unexpected" ) )
                    } );
                } ).catch( () =>
                {
                } );
            },
            /**
             * Updating an email invitation template.
             */
            updateEmailTemplate()
            {
              this.$prompt({
                title : this.$t( "signflow.CreateTemplateTitle" ),
                text : this.$t( "signflow.UpdateTemplate" ),
                cancelText : this.$t("signflow.Cancel"),
                acceptText : this.$t("signflow.Save"),
                defaultValue : this.nameTemplate || ""
              } ).then( newTemplateName =>
              {
                if ( this.nameTemplate )
                {
                  if (newTemplateName)
                  {
                    this.nameTemplate = newTemplateName;
                    this.createEmailTemplate();
                  }
                  else
                  {
                    this.doUpdateEmailTemplate();
                  }
                }
                else
                {
                  if (newTemplateName)
                  {
                    this.nameTemplate = newTemplateName;
                    this.createEmailTemplate();
                  }
                  else
                  {
                      this.$prompt({
                          title : this.$t( "signflow.CreateTemplateTitle" ),
                          text : this.$t( "signflow.UpdateTemplate" ),
                          cancelText : this.$t("signflow.Cancel"),
                          acceptText : this.$t("signflow.Save"),
                          defaultValue : this.nameTemplate || this.$t("signflow.TemplateNoname"),
                      } ).then( newTemplateName =>
                          {
                              if ( this.nameTemplate )
                              {
                                  if (newTemplateName)
                                  {
                                      this.nameTemplate = newTemplateName;
                                      this.createEmailTemplate();
                                  }
                                  else
                                  {
                                      this.doUpdateEmailTemplate();
                                  }
                              }
                              else
                              {
                                  if (newTemplateName)
                                  {
                                      this.nameTemplate = newTemplateName;
                                      this.createEmailTemplate();
                                  }
                                  else
                                  {
                                      this.$error( this.$t( "signflow.Error!TemplateNameEmpty" ) );
                                  }
                              }
                          }
                      ).catch( e => {} );
                  }
                }
                }
                ).catch( e => {} );

            },

            /**
             * Update an email invitation template.
             */
            doUpdateEmailTemplate()
            {
                {
                    this.$store.signFlowClient.updateEmailTemplate( $cc_user_info.organization.uuid,
                    this.templateId,
                    this.emailSubject,
                    this.emailMessage,
                    this.nameTemplate ).then(
                    () =>
                    {
                        this.$warn( this.$t( 'client.YourChangesHaveBeenSaved' ) );
                        this.retrieveEmailTemplates();
                        this.chose_template_name = this.nameTemplate;
                        this.templateChanged = false;
                    } ).catch( e =>
                    {
                        this.$error( this.$t( "signflow.Error!Unexpected" ) );
                    } );
                }
            },

          onTemplateChange()
          {
              this.templateChanged = true;
          },

          FillTemplate()
          {
            this.foundTemplateData = this.template_list.find( templates => templates.nameTemplate === this.chose_template_name );
            this.emailSubject = this.foundTemplateData.emailSubject;
            this.original_template.emailSubject = this.emailSubject;
            this.emailMessage = this.foundTemplateData.emailMessage;
            this.original_template.emailMessage = this.emailMessage;
            this.nameTemplate = this.chose_template_name;
            this.original_template.nameTemplate = this.nameTemplate;
            this.templateId = this.foundTemplateData.templateId;
          }
        },
        /**
         * Set listener for relevant server messages and set flow type to "only_me".
         */
        mounted()
        {
            if( window.$cc_user_info.organization.org_signature_types )
            {
                if( window.$cc_user_info.organization.org_signature_types?.includes( "email-based-signature" ) )
                {
                    this.enabledLevels.push( "basic" );
                }
                if( window.$cc_user_info.organization.org_signature_types.length > 1 )
                {
                    this.enabledLevels.push( "advanced" );
                }
                if( window.$cc_user_info.organization.org_signature_types[ 0 ] === "email-based-signature" )
                {
                    this.signatureLevel = "basic";
                }
                else
                {
                    this.signatureLevel = "advanced";
                    this.selected_signature_types = [ window.$cc_user_info.organization.org_signature_types[ 0 ] ];
                    this.applyAdvancedMethods();
                }
                const signature_types = window.$cc_user_info.organization.org_signature_types.slice();
                this.enabled_advanced_signature_types =
                signature_types.filter( item => item !== "email-based-signature" );
            }
            this.$store.shioCoreClient.retrieveUsers( util.getOrganizationId() ).then( r =>
            {
                this.orgUsers = r.entities;
            } ).catch( e =>
            {
                console.error( e );
                this.$error( this.$t( "signflow.Error!Unexpected" ) );
                this.terminate( e );
            } );
            if( this.$route.query.event_id )
            {
                this.$store.signFlowClient.retrieveSignFlow( this.$route.query.group_id, this.$route.query.process_uuid ).then( signFlow =>
                {
                    this.$router.push(this.$route.path);
                    if( signFlow )
                    {
                        let signFlowParams = this._getSignFlowParams( signFlow );
                        this._prefill( signFlowParams );
                    }
                } ).catch( e =>
                {
                    console.error( e );
                    this.$error( this.$t( "signflow.Error!Unexpected" ) );
                    this.terminate( e );
                } );
            }
            else if( this.$store.flow_to_create )
            {
                this._prefill( this.$store.flow_to_create );
            }
            else
            {
                this.setFlowType( "only_me" );
            }
        },
        /**
         * Retrieve templates after created it.
         */
        created() {
            this.retrieveEmailTemplates()
        }
    };
</script>

<style lang="sass" scoped>
  @import "../../../../styles/style"

  .custom-start-text
    color: $vivid-pink

  .custom-pink-text
    color: $vivid-pink

  .custom-text-text
    color: gray
    margin-bottom: 0px

  .shio_invisible_content
    visibility: hidden

  .shio_hide_content
    display: none

  .shio-sign-card-input
    width: 92%

  .shio-file-chip
    border: none

  .shio-file-download-link
    color: inherit
    text-decoration: underline
    overflow: hidden
    white-space: normal
    text-overflow: ellipsis

  .shio-file-chips-container
    text-align: left

  span.shio-file-chip.v-chip
    height: auto

  .shio-extras-content::v-deep .v-expansion-panel-content__wrap
    padding-left: 10px !important

  .shio-choose-flow
    background: white !important
    height: 100%
    border: 1px solid $vivid-pink
    font-size: 22px

  .shio-choose-flow-active
    color: $vivid-pink
    box-shadow: none !important

  .shio-choose-flow.v-btn.v-btn--active:before
    background-color: $light-grey !important
    opacity: 1 !important

  .shio-choose-flow.v-btn:before
    background-color: white !important
    opacity: 1 !important

  .shio-choose-flow.v-btn:hover
    background-color: $light-grey !important
    opacity: 1 !important
    box-shadow: none !important

  .shio-choose-flow.v-btn:hover:before
    background-color: $light-grey !important

  .information-icon
    color: $vivid-pink

  .v-expansion-panel-header
    background-color: $light-grey

  .shio-expansion-panel
    border: 1px solid $light-grey

  .choose-template-icon
    color: $text-black

  .btn-row
    justify-content: space-between

  .marginTop-60
    margin-top: 60px

  .chose-template
    color: $dark-grey
    font-size: 14px

  .invisible
    visibility: hidden

  .apply-signature-types-btn
    position: absolute
    bottom: 30px
    right: 40px

  .basic-level-info, .advanced-level-info
    margin-top: 15px

  @media (max-width: 599px)
    .v-btn
      margin-left: auto !important
      margin-right: auto !important

    .btn-row
      justify-content: center

    .confirm-btn
      margin-top: 20px

  @media (max-width: 959px)
   .extras-section
     display: none
</style>

<style lang="sass">
  .signature-types-dialog .v-input__control
    margin: auto !important
</style>