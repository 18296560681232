<template>
  <v-container fluid class="fill-height pa-0">
    <v-row no-gutters class="fill-height">
      <v-col class="dark-image-bg pa-6 hidden-sm-and-down">
        <v-row align="center" class="fill-height">
          <v-flex>
            <h1 class="welcome-message text-center pb-4 font-weight-regular" v-html="$t( 'message.AlreadyHaveSignHeroAccount' )"></h1>
            <p>
              <v-btn class="primary-btn" to="/signin">{{ $t( "message.SignInHere" ) }}</v-btn>
            </p>
          </v-flex>
        </v-row>
      </v-col>
      <v-col align="center" class="light pa-6 ma-auto">
        <div class="sign-in-up pt-12 pt-sm-0 pt-md-0 pt-lg-0 pt-xl-0">
          <v-form class="text-left" v-model="formValid">
            <h2 class="welcome-message pb-1 text-left font-weight-regular" v-html="$t( 'message.CreateYourSignHeroAccount' )"></h2>
            <p class="pb-2">{{ $t( "message.EnterYourDetailsBelow" ) }}</p>
            <div class="pb-1">
              <v-text-field ref="name" v-model="name" :label="$t( 'message.NameAndSurname' )"
                            :rules="[validateFullName]"
                            :disabled="disabled" @keydown="checkSubmit"></v-text-field>
            </div>
            <div class="pb-1">
              <v-text-field type="email" :rules="[ validateEmail ]" ref="email" v-model="email" :label="$t( 'message.Email' )"
                            @change="removeZeroSpaceForEmail()"
                            :disabled="disabled" @keydown="checkSubmit"></v-text-field>
            </div>
            <div class="pb-1">
              <v-text-field aria-required="true" ref="company" v-model="company" :label="$t( 'message.Organization' )"
                            :rules="[validateFullName]"
                            :disabled="disabled" @keydown="checkSubmit"></v-text-field>
            </div>
          </v-form>
          <div class="terms-disclaimer py-4" v-html="$t( 'message.AcceptTermsOfUseAndPrivacyPolicy' )">
          </div>
          <div>
            <div>
              <div v-if="!disabled" id="robot_check_div"></div>
              <v-btn @click="signUp()" :disabled="!valid || disabled || !robot_check" class="primary-btn mt-3">
                {{ $t( 'message.SignUpButtonLabel' ) }}
              </v-btn>
            </div>
            <p class="mt-7" v-bind:class="{ 'cvw-status-message': message }">{{ message }}</p>
          </div>
        </div>
        <div class="hidden-md-and-up have-account-small-screen pt-16">
          <v-divider class="pb-10"></v-divider>
          <h3 class="welcome-message text-center font-weight-regular pb-3" v-html="$t( 'message.AlreadyHaveSignHeroAccount' )"></h3>
          <p>
            <v-btn class="primary-btn" to="/signin">{{ $t( "message.SignInHere" ) }}</v-btn>
          </p>
        </div>
      </v-col>
    </v-row>
    <sh-app-footer></sh-app-footer>
  </v-container>
</template>

<script>
    import {JSONPureClient} from "@cloudlace/client";
    import selfEnrollmentServiceMap from "../../api/self-enrollment-service-map";
    import util from "../../util/util";
    import AppFooter from "../../components/common/AppFooter";

    const signupClient = new JSONPureClient( {
        service_url : process.env.VUE_APP_SERVICE_SIGNUP_URL,
        service_map : selfEnrollmentServiceMap
    } );
    export default {
        name : "signup-pane",
        data()
        {
            return {
                formValid : false,
                email : "",
                name : "",
                company : "",
                message : "",
                disabled : false,
                robot_check : false
            }
        },
        components : {
            "sh-app-footer" : AppFooter,
        },
        computed : {
            valid()
            {
                return this.formValid && this.email && this.name && this.company;
            }
        },
        methods : {
            /**
             * Email validator.
             */
            validateEmail : util.validateEmail,
            /**
             * Full name validator.
             */
            validateFullName : util.validateFullName,
            /**
             * Sign up a new user.
             */
            signUp()
            {
                this.disabled = true;
                signupClient.createUser( this.email,
                {
                    email : this.email,
                    name : this.name,
                    company : this.company,
                    locale : util.getLocale(),
                    signup_token : this.recaptcha_token
                },
                "shio.create" ).then( res =>
                {
                    this.message = this.$t( "message.UserSignedUp" );
                } ).catch( err =>
                {
                    if( err.code_key === 409 )
                    {
                        // Prevent email address phishing when creating a new user account
                        // E-mail with instructions on how to activate the account is not sent when the user already exists
                        this.message = this.$t( "message.UserSignedUp" );
                    }
                    else
                    {
                        this.message = this.$t( "message.Error!Unexpected" );
                    }
                } );
            },
            /**
             * If the user hit "Enter", sign up.
             *
             * @param evt
             */
            checkSubmit( evt )
            {
                if( evt.code === "Enter" && this.valid && this.robot_check )
                {
                    this.signUp();
                }
            },
            /**
             * Removes zero-width-space.
             *
             */
            removeZeroSpaceForEmail()
            {
                this.email = this.email.replace( /[\u200B-\u200F\uFEFF]/g, '' );
            },
            /**
             * Verify robot check callback.
             *
             * @param response
             */
            verifyRecaptchaCallback( response )
            {
                this.robot_check = true;
                this.recaptcha_token = response;
            },
            /**
             * Render robot check div.
             *
             */
            renderRobotCheck()
            {
                setTimeout( function()
                {
                    if( typeof grecaptcha === 'undefined' || typeof grecaptcha.render === 'undefined' )
                    {
                        this.renderRobotCheck();
                    }
                    else
                    {
                        grecaptcha.render( "robot_check_div", {
                            'sitekey' : process.env.VUE_APP_GOOGLE_RECAPTCHA_KEY,
                            'callback' : this.verifyRecaptchaCallback,
                            'theme' : 'light',
                            'hl' : util.getLocale()
                        } );
                    }
                }.bind( this ), 100 );
            }
        },
        mounted()
        {
            if( typeof grecaptcha === 'undefined' )
            {
                const recaptchaScript = document.createElement( "script" );
                recaptchaScript.setAttribute( "src", process.env.VUE_APP_RECAPTCHA_SCRIPT_URL );
                document.head.appendChild( recaptchaScript );
            }
            this.renderRobotCheck();
        }
    };
</script>

<style lang="sass">
  @import "../../styles/style"

  .have-account-small-screen
    max-width: 450px

  .terms-disclaimer
    font-size: 14px

  a.terms-disclaimer-link
    color: $text-black !important

  a.terms-disclaimer-link:hover
    color: $vivid-pink !important
</style>
