import SignInPane from "../../components/signedout/SignInPane";
import SignUpPane from "../../components/signedout/SignUpPane";
import AcceptPane from "../../components/signedout/AcceptPane";
import LandingPane from "../../components/common/LandingPane";
import ApiDocument from "../../components/common/ApiDocument";
import Roadmap from "../../components/common/api-doc-sections/Roadmap";
import Overview from "../../components/common/api-doc-sections/Overview";
import Authentication from "../../components/common/api-doc-sections/Authentication";
import ClientCredentials from "../../components/common/api-doc-sections/ClientCredentials";
import RequestAnAccessToken from "../../components/common/api-doc-sections/RequestAnAccessToken";
import ClientCredentialsRevokeTokens from "../../components/common/api-doc-sections/ClientCredentialsRevokeTokens";
import AuthorisationCode from "../../components/common/api-doc-sections/AuthorisationCode";
import CreateAnApplication from "../../components/common/api-doc-sections/CreateAnApplication";
import RequestAnAuthorisationCode from "../../components/common/api-doc-sections/RequestAnAuthorisationCode";
import ExchangeTheAuthorisationCodeToAnAccessToken from "../../components/common/api-doc-sections/ExchangeTheAuthorisationCodeToAnAccessToken";
import RefreshAnAccessToken from "../../components/common/api-doc-sections/RefreshAnAccessToken";
import AuthorisationCodeRevokeTokens from "../../components/common/api-doc-sections/AuthorisationCodeRevokeTokens";
import ErrorResponses from "../../components/common/api-doc-sections/ErrorResponses";
import UploadADocument from "../../components/common/api-doc-sections/UploadADocument";
import SearchUnattachedDocuments from "../../components/common/api-doc-sections/SearchUnattachedDocuments";
import DeleteAnUnattachedDocument from "../../components/common/api-doc-sections/DeleteAnUnattachedDocument";
import CreateASigningProcess from "../../components/common/api-doc-sections/CreateASigningProcess";
import CreateASigningURL from "../../components/common/api-doc-sections/CreateASigningURL";
import RetrieveASigningProcess from "../../components/common/api-doc-sections/RetrieveASigningProcess";
import SearchSigningProcess from "../../components/common/api-doc-sections/SearchSigningProcess";
import CancelASigningProcess from "../../components/common/api-doc-sections/CancelASigningProcess";
import DeleteASigningProcess from "../../components/common/api-doc-sections/DeleteASigningProcess";
import DownloadADocument from "../../components/common/api-doc-sections/DownloadADocument";
import RetrieveProfileInfo from "../../components/common/api-doc-sections/RetrieveProfileInfo";

export default [
    {
        path : "/",
        name : "base",
        component : LandingPane
    },
    /*
      {
           path : "/",
           name : "base",
           component : SignUpPane
       },*/
    {
        path : "/accept",
        name : "accept",
        component : AcceptPane
    },
    {
        path : "/signup",
        name : "signup",
        component : SignUpPane
    },
    {
        path : "/signin",
        name : "signin",
        component : SignInPane
    },
    {
        path : "/resetpw",
        name : "resetpw",
        component : SignInPane
    },
    {
        path : "/confirm",
        name : "confirm",
        component : SignInPane
    },
    {
        path : "/pwless",
        name : "pwless",
        component : SignInPane
    },
    {
        path : "/*",
        redirect : "/"
    },
]
