const uuidRegExp = "[0-9a-fA-F]{8}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{12}";
const statusObject = {
    type : "object",
    properties : {
        "status_code" : { type : "number" },
        "status_msg" : { type : "string" },
        "started" : { oneOf : [ { type : "string" }, { type : "number" } ] },
        "completed" : { type : "number" }
    }
};
module.exports = {
    /**
     * Signature flow schema.
     *
     * @public Object
     */
    flowSchema : {
        id : "/SignFlow",
        type : "Object",
        properties : {
            title : { type : "string", maxLength : 900 },
            locale : { type : "string", enum : [ "en", "fi", "sv", "pl", "de", "nl", "fr", "es", "th" ] },
            signers : {
                type : "array",
                minItems : 1,
                maxItems : 900,
                items : {}
            }
        },
        additionalProperties : false,
        required : [ "documents", "signers" ]
    },
    /**
     * Signers schema.
     *
     * @public Object
     */
    signersSchema : {
        id : "/SignersSchema",
        type : "array",
        items : { oneOf : [ this.meSignerSchema, this.othersSignerSchema ] },
        minItems : 1
    },
    _signersSchema : {
        id : "/_SignersSchema",
        "type" : "array",
        "items" : { "oneOf" : [ this.meSignerSchema, this.othersSignerSchema ] },
        minItems : 1
    },
    /**
     * Others signer schema.
     *
     * @public Object
     */
    othersSignerSchema : {
        id : "/OthersSigner",
        type : "object",
        properties : {
            signerName : { type : "string", minLength : 1 },
            signerEmail : { type : "string", format : "email", minLength : 1 },
            signerType : { type : "string", enum : [ "others" ] },
            locale : { type : "string", enum : [ "en", "fi", "sv", "pl", "de", "nl", "fr", "es", "th" ] },
            allowedSignatureTypes : { "type" : "array", "items" : { "type" : "string" } }
        },
        additionalProperties : false,
        required : [ "signerName", "signerEmail", "signerType" ]
    },
    /**
     * Me signer schema.
     *
     * @public Object
     */
    meSignerSchema : {
        id : "/MeSigner",
        type : "object",
        properties : {
            signerType : { type : "string", enum : [ "me" ] },
            signerName : { type : "string", minLength : 1 },
            signerEmail : { type : "string", format : "email", minLength : 1 },
            locale : { type : "string", enum : [ "en", "fi", "sv", "pl", "de", "nl", "fr", "es", "th" ] },
            allowedSignatureTypes : { "type" : "array", "items" : { "type" : "string" } }
        },
        additionalProperties : false,
        required : [ "signerName", "signerEmail", "signerType" ]
    },
    /**
     * Document schema
     *
     * @public Object
     */
    docSchema : {
        id : "/Document",
        type : "object",
        properties : {
            type : { type : "string", enum : [ "documents" ] },
            uuid : { type : "string", pattern : uuidRegExp },
            fileName : { type : "string" },
            pages : { type : "number" },
            receipt : {
                type : "object",
                properties : {
                    height : { type : "string", pattern : "\\d+mm" },
                    width : { type : "string", pattern : "\\d+mm" },
                    pages : { type : "number" }
                }
            },
            signatures : {
                type : "array",
                items : { $ref : "/Signature" },
                minItems : 0
            },
            size : { type : "number", minimum : 0 }
        },
        additionalProperties : false
    },
    signEventSchema : {
        id : "/SignEvent",
        type : "object",
        properties : {
            type : { type : "string", enum : [ "sign-events" ] },
            uuid : { type : "string", pattern : uuidRegExp },
            authMethod : { type : "string", enum : [ "email", "access_token" ] },
            locale : { type : "string" },
            loginTime : { type : "number" },
            signed : { type : "boolean" },
            signerEmail : { type : "string", format : "email" },
            signerName : { type : "string" },
            signerNumber : { type : "number" },
            signerType : { type : "string", enum : [ "me", "others" ] },
            _signing_status : { type : "string", enum : [ "pending", "completed", "failed" ] },
            _signing_status_changed : { type : "number" }
        },
        additionalProperties : true,
        required : []
    },
    signProcessSchema : {
        id : "/SignProcess",
        type : "object",
        properties : {
            type : { type : "string", enum : [ "process" ] },
            uuid : { type : "string", pattern : uuidRegExp },
            title : { type : "string" },
            created : { type : "number" },
            groupId : { type : "string", pattern : uuidRegExp },
            groupTitle : { type : "string" },
            emailMessage : { type : "string" },
            hidden : { type : "boolean" },
            active : { type : "boolean" },
            canceled : { type : "boolean" },
            completed : { type : "boolean" },
            declined : { type : "boolean" },
            pendingMe : { type : "boolean" },
            pendingOthers : { type : "boolean" },
            declinationReasons : { type : "string" },
            cancellationReasons : { type : "string" },
            signatureReceiptVersion : { type : "number" },
            toc : { type : "number" },
            decider : {
                type : "object", properties :
                {
                    uuid : { type : "string", pattern : uuidRegExp },
                    email : { type : "string", format : "email" },
                    name : { type : "string" }
                }
            },
            locale : { type : "string" },
            creator : { type : "string", pattern : uuidRegExp },
            flowType : { type : "string", enum : [ "only_me", "only_others", "me_and_others" ] },
            flowVersion : { type : "number" },
            lastEmailReminder : { type : "number" },
            requester : {
                groupId : { type : "string", pattern : uuidRegExp },
                groupTitle : { type : "string" },
                email : { type : "string", format : "email" },
                name : { type : "string" },
                uuid : { type : "string", pattern : uuidRegExp }
            },
            signers : {
                type : "array", items :
                {
                    type : "object",
                    properties : {
                        signerType : { type : "string", enum : [ "me", "others" ] },
                        signerEmail : { type : "string", format : "email" },
                        signerName : { type : "string" },
                        authMethod : { type : "string", enum : [ "email", "access_token" ] },
                        locale : { type : "string" }
                    }
                }
            },
            tags : {
                type : "array",
                items : {
                    type : "string",
                    minLength : 1,
                    maxLength : 20
                }
            },
            _locked : { type : "boolean" },
            _locked_for : { type : "string", pattern : uuidRegExp },
            _locked_by : { type : "string", format : "email" },
            _lock_time : { type : "number" }
        },
        additionalProperties : false,
        required : []
    },
    /**
     * Signature schema.
     *
     * @public Object
     */
    signatureSchema : {
        id : "/Signature",
        type : "object",
        properties : {
            page : { type : "number" },
            top : { type : "number" },
            left : { type : "number" },
            height : { type : "number" },
            width : { type : "number" },
            scale : { type : "number", minimum : 0.5, maximum : 2 },
            textAlign : { type : "string", enum : [ "left", "center", "right" ] }
        },
        additionalProperties : false,
        required : [ "page", "top", "left", "width", "height", "scale" ]
    },
    tokenSchema : {
        id : "/Token",
        type : "object",
        properties : {
            name : { type : "string" },
            uuid : { type : "string", pattern : uuidRegExp },
            created : { type : "number" },
            exp : { type : "number" },
            scope : { type : "string", enum : [ "sign", "download" ] },
            processUuid : { type : "string", pattern : uuidRegExp },
            eventUuid : { type : "string", pattern : uuidRegExp },
            userUuid : { type : "string", pattern : uuidRegExp }
        },
        additionalProperties : false,
        required : [ "name", "created", "scope", "processUuid", "eventUuid", "userUuid" ]
    },
    /**
     * Font schema.
     *
     * @public Object
     */
    fontSchema : {
        id : "/Font",
        type : "object",
        properties : {
            fontSize : { type : "number", minimum : 8, maximum : 36 },
            fontColor : { type : "string", enum : [ "#000000", "black" ] },
            fontFamily : { type : "string", enum : [ "LiberationSans", "LiberationSans-Bold" ] },
            fontStyle : { type : "string", enum : [ "bold" ] }
        },
        additionalProperties : false,
        required : []
    },
    auditSchema : {
        id : "/Audit",
        oneOf : [ {
            id : "/Audit/ProcessCreated",
            type : "object",
            properties : {
                type : { type : "string", enum : [ "audit-events" ] },
                uuid : { type : "string", pattern : uuidRegExp },
                time : { type : "number" },
                client : { type : "string" },
                user_email : { type : "string", format : "email" },
                user_name : { type : "string" },
                user_uuid : { type : "string", pattern : uuidRegExp },
                process_uuid : { type : "string", pattern : uuidRegExp },
                event_type : { type : "string", enum : [ "process_created" ] },
                documents : { type : "array", items : { type : "string", pattern : uuidRegExp } },
                events : { type : "array", items : { type : "string", pattern : uuidRegExp } }, // what's this?
                //group_path : { type : "string" },
                // si : { type : "string" }
            }
        }, {
            id : "/Audit/SigningCompleted",
            type : "object",
            properties : {
                type : { type : "string", enum : [ "audit-events" ] },
                uuid : { type : "string", pattern : uuidRegExp },
                name : { type : "string" },
                time : { type : "number" },
                client : { type : "string" },
                user_email : { type : "string", format : "email" },
                user_name : { type : "string" },
                user_uuid : { type : "string", pattern : uuidRegExp },
                user_type : { type : "string", enum : [ "external", "internal" ] },
                process_uuid : { type : "string", pattern : uuidRegExp },
                event_type : { type : "string", enum : [ "signing_completed" ] },
                event_uuid : { type : "string", pattern : uuidRegExp }
            }
        } ],
    }
};