<template>
  <v-card flat :width="width">
    <v-card-title>
      <h2>{{ $t( 'message.HelloThere') }} {{ entity.preferred_username || entity.name || entity.given_name ||
        entity.email }}</h2>
    </v-card-title>
    <v-card-text :class="editing ? 'profile-form-editing' : ''">
      <v-flex class="profile-form">
        <div class="text-left">
          <cc-image-uploader v-if="_fields.picture"
                             :src="entity.picture || ''"
                             :aspect-ratio="1"
                             :height="150"
                             :width="150"
                             :locked="!editing"
                             :uploadPath="avatarUploadPath"
                             :downloadPath="avatarDownloadPath"
                             @change="uploadAvatar"
                             @success="updateAvatar"
          ></cc-image-uploader>
        </div>
        <v-text-field @input="updateData"
                      @keydown="checkSave"
                      v-if="editing && _fields.picture"
                      :rules="[rules.url]"
                      :label="$t( 'message.picture' )"
                      v-model="entity.picture"></v-text-field>
        <v-text-field @input="updateData"
                      @keydown="checkSave"
                      :disabled="!editing"
                      :rules="[rules.fullname]"
                      :label="$t( 'message.name' )"
                      v-model="entity.name"></v-text-field>
        <v-text-field v-if="_fields.given_name"
                      @input="updateData"
                      @keydown="checkSave"
                      :disabled="!editing"
                      :label="$t( 'message.given_name' )"
                      v-model="entity.given_name"></v-text-field>
        <v-text-field v-if="_fields.middle_name"
                      @input="updateData"
                      @keydown="checkSave"
                      :disabled="!editing"
                      :label="$t( 'message.middle_name' )"
                      v-model="entity.middle_name"></v-text-field>
        <v-text-field v-if="_fields.family_name"
                      @input="updateData"
                      @keydown="checkSave"
                      :disabled="!editing"
                      :label="$t( 'message.family_name' )"
                      v-model="entity.family_name"></v-text-field>
        <v-text-field v-if="_fields.nickname"
                      @input="updateData"
                      @keydown="checkSave"
                      :disabled="!editing"
                      :label="$t( 'message.nickname' )"
                      v-model="entity.nickname"></v-text-field>
        <v-text-field v-if="_fields.preferred_username"
                      @input="updateData"
                      @keydown="checkSave"
                      :disabled="!editing"
                      :label="$t( 'message.preferred_username' )"
                      v-model="entity.preferred_username"></v-text-field>
        <v-text-field v-if="_fields.phone_number"
                      @input="updateData"
                      @keydown="checkSave"
                      :rules="[rules.phone]"
                      :disabled="!editing"
                      :label="$t( 'message.phone_number' )"
                      v-model="entity.phone_number"></v-text-field>
        <div v-if="_fields.address">
          <v-text-field @input="updateData"
                        @keydown="checkSave"
                        :disabled="!editing"
                        :label="$t( 'message.street_address' )"
                        v-model="int_address.street_address"></v-text-field>
          <v-text-field @input="updateData"
                        @keydown="checkSave"
                        :disabled="!editing"
                        :label="$t( 'message.locality' )"
                        v-model="int_address.locality"></v-text-field>
          <v-text-field @input="updateData"
                        @keydown="checkSave"
                        :disabled="!editing"
                        :label="$t( 'message.postal_code' )"
                        v-model="int_address.postal_code"></v-text-field>
          <v-text-field v-if="_fields.address_region"
                        @input="updateData"
                        @keydown="checkSave"
                        :disabled="!editing"
                        :label="$t( 'message.region' )"
                        v-model="int_address.region"></v-text-field>
          <v-text-field v-if="_fields.address_country"
                        @input="updateData"
                        @keydown="checkSave"
                        :disabled="!editing"
                        :label="$t( 'message.country' )"
                        v-model="int_address.country"></v-text-field>
        </div>
        <v-text-field v-if="_fields.profile"
                      @input="updateData"
                      @keydown="checkSave"
                      :rules="[rules.url]"
                      :disabled="!editing"
                      :label="$t( 'message.profile' )"
                      v-model="entity.profile"></v-text-field>
        <v-text-field v-if="_fields.website"
                      @input="updateData"
                      @keydown="checkSave"
                      :rules="[rules.url]"
                      :disabled="!editing"
                      :label="$t( 'message.website' )"
                      v-model="entity.website"></v-text-field>
        <v-radio-group v-if="_fields.gender"
                       @change="updateData"
                       :disabled="!editing"
                       :label="$t( 'message.gender' )"
                       v-model="entity.gender">
          <v-radio
              key="female"
              :label="$t( 'message.female' )"
              value="female"
          ></v-radio>
          <v-radio
              key="male"
              :label="$t( 'message.male' )"
              value="male"
          ></v-radio>
          <v-radio
              key="other"
              :label="$t( 'message.other' )"
              value="other"
          ></v-radio>
        </v-radio-group>
        <v-menu
            v-if="_fields.birthdate"
            v-model="birthDateMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            :disabled="!editing"
            transition="scale-transition"
            offset-y
            min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
                :value="birthdate_formatted"
                :disabled="!editing"
                :label="$t( 'message.birthdate' )"
                prepend-icon="mdi-calendar"
                readonly
                v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="entity.birthdate" @input="updateData"></v-date-picker>
        </v-menu>
        <v-select v-if="available_locales.length > 0 " :label="$t( 'message.PreferredLanguage' )"
                  v-model="entity.locale"
                  :disabled="!editing"
                  :items="available_locales"
                  @input="updateData"></v-select>
        <v-text-field readonly
                      :label="$t( 'message.email' )"
                      disabled
                      v-model="entity.email"></v-text-field>
      </v-flex>
      <v-flex shrink>
        <v-expand-transition>
          <v-flex v-show="changing_password">
            <p>
              <v-divider></v-divider>
            </p>
            <h3 class="cvw-change-password-title">{{ $t( "message.ChangePassword" ) }}</h3>
            <v-text-field ref="current_password_field"
                          @keydown="checkChangePassword"
                          type="password"
                          :label="$t( 'message.CurrentPassword' )"
                          v-model="current_password"></v-text-field>
            <signhero-set-password-field
                ref="new_password_field"
                v-model="new_password"
                @keydown="checkChangePassword"
                :label="$t( 'message.NewPassword' )"
                :placeholder="$t( 'message.NewPassword' )"
                :rules="[isPasswordValid]"
                @input="validateNewPassword"
            >
            </signhero-set-password-field>
            <p class="text-left password-requirement">
              {{ $t( "message.PasswordRequirement" ) }}
            </p>
            <signhero-set-password-field
                ref="repeat_password_field"
                v-model="repeat_password"
                @keydown="checkChangePassword"
                :label="$t( 'message.RepeatPassword' )"
                :placeholder="$t( 'message.RepeatPassword' )"
                :rules="[isRepeatPasswordValid]"
                @input="validateRepeatPassword"
            >
            </signhero-set-password-field>
          </v-flex>
        </v-expand-transition>
      </v-flex>
    </v-card-text>
    <v-card-actions class="cvw-my-profile-action">
      <v-row no-gutters>
        <v-col class="pa-1" :cols="button_cols" v-if="!editing && !changing_password">
          <v-btn block @click="editing=true" class="cvw-edit-profile-btn">{{ $t( 'message.Action!EditProfile' ) }}
          </v-btn>
        </v-col>
        <v-col class="pa-1" :cols="button_cols" v-if="!editing && !changing_password">
          <v-btn block @click="changing_password = true" class="cvw-change-password-btn">{{ $t( 'message.ChangePassword'
            ) }}
          </v-btn>
        </v-col>
        <v-col class="pa-1" :cols="button_cols" v-if="editing || changing_password">
          <v-btn block @click="resetChanges" class="cvw-reset-btn">{{ $t( 'message.Reset' ) }}</v-btn>
        </v-col>
        <v-col>
          <v-spacer></v-spacer>
        </v-col>
        <v-col class="pa-1" :cols="button_cols" v-if="!editing && !changing_password">
          <v-btn block color="primary" @click="signOut" class="cvw-sign-out-btn">{{ $t( 'message.Action!SignOut' ) }}
          </v-btn>
        </v-col>
        <v-col class="pa-1" :cols="button_cols" v-if="changing_password">
          <v-btn block color="primary" @click="setPassword"
                 :disabled="!valid_password_inputs || acting" class="cvw-profile-set-password-btn">{{ $t(
            "message.Action!SetPassword" ) }}
          </v-btn>
        </v-col>
        <v-col class="pa-1" :cols="button_cols" v-if="editing">
          <v-btn block :disabled="!entity.$dirty || acting || username_invalid" color="primary" @click="saveChanges" class="cvw-update-btn">
            {{ $t(
            'message.Update' ) }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
    import {globalEvents, JSONPureClient} from "@cloudlace/client";
    import {format} from "date-fns";
    import signedInServiceSchema from "../../../../node_modules/@cloudlace/vue-widgets/src/api/signedin-service-schema";
    import signedOutServiceSchema from "../../../../node_modules/@cloudlace/vue-widgets/src/api/signedout-service-schema";
    import SignheroSetPasswordField from "../../signedout/SignHeroSetPasswordField";
    import tester from "owasp-password-strength-test";
    import util from "../../../util/util";

    const signedInClient = new JSONPureClient( {
        service_url : process.env.VUE_APP_USER_SIGNEDIN_URL,
        service_map : signedInServiceSchema
    } );
    const signedOutClient = new JSONPureClient( {
        service_url : process.env.VUE_APP_USER_SIGNEDOUT_URL,
        service_map : signedOutServiceSchema
    } );
    export default {
        name : "signhero-user-profile",
        components : { SignheroSetPasswordField },
        props : {
            width : {
                type : [ Number, String ],
                default : "auto"
            },
            optional_properties : String
        },
        data()
        {
            let dlPath = process.env.VUE_APP_AVATAR_DOWNLOAD_PATH;
            const locales = typeof process.env.VUE_APP_UI_LOCALES === "undefined" ? [ 'en',
                                                                                      'fi' ] : process.env.VUE_APP_UI_LOCALES ? process.env.VUE_APP_UI_LOCALES.split(
            ',' ) : [];
            if( dlPath.indexOf( "/" ) === 0 )
            {
                dlPath = window.location.origin + dlPath;
            }
            return {
                rules : {
                    url : str =>
                    {
                        return str
                               === ""
                               || /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)$/.test(
                        str )
                               || this.$t( "message.Error!InvalidUrl" );
                    },
                    fullname : str =>
                    {
                        return str
                               === ""
                               || !(/(?!<a[^>]*>[^<])(((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?))(?![^<]*<\/a>)/gi).test(
                        str )
                               || this.$t( "message.Error!InvalidFullName" );
                    },
                    phone : str =>
                    {
                        return str === "" || /^\+?[1-9]{0,3}\s?[0-9]{0,4}\s?[0-9]{1,14}$/.test( str ) || this.$t(
                        "message.Error!InvalidPhoneNumber" );
                    }
                },
                available_locales : locales,
                int_address : {},
                birthDateMenu : false,
                entity : {},
                editing : false,
                changing_password : false,
                acting : false,
                current_password : "",
                new_password : "",
                repeat_password : "",
                alertMessage : "",
                tab : "users",
                avatarMaxSize : 131071,
                avatarUploadPath : process.env.VUE_APP_AVATAR_UPLOAD_PATH,
                avatarDownloadPath : dlPath,
                isPasswordValid : "",
                isRepeatPasswordValid : "",
                isResetPw : false
            }
        },
        computed : {
            button_cols()
            {
                if( this.$el || typeof this.width === "number" )
                {
                    const w = this.width || this.$el.clientWidth;
                    if( w > this.$vuetify.breakpoint.thresholds.sm )
                    {
                        return 3;
                    }
                    else
                    {
                        return 12;
                    }
                }
                else if( this.$vuetify.breakpoint.mdAndUp )
                {
                    return 3;
                }
                else
                {
                    return 12;
                }
            },
            _fields()
            {
                const out = {
                    name : true,
                    locale : true
                };
                const props = (this.optional_properties || "").split( ',' );
                for( let i = 0; i < props.length; i++ )
                {
                    out[ props[ i ] ] = true;
                }
                return out;
            },
            birthdate_formatted()
            {
                return this.entity.birthdate ? format( new Date( this.entity.birthdate ),
                this.$t( 'message.dateFormat' ) ) : "";
            },
            valid_password_inputs()
            {
                return this.current_password.length >= 8
                       && this.new_password
                       && this.new_password === this.repeat_password;
            },
            username_invalid()
            {
                return ( this.validateFullName( this.entity.name ) !== true || !this.entity.name );
            }
        },
        methods : {
            /**
             * Full name validator.
             */
            validateFullName : util.validateFullName,
            uploadAvatar( uploader )
            {
                const path = "/" + this.entity.email + "/avatar.png";
                uploader.uploadAsset( path );
            },
            updateAvatar( uploader )
            {
                this.$set( this.entity,
                "picture",
                this.avatarDownloadPath
                + uploader.asset.asset.asset_path
                + '?version='
                + uploader.asset.asset.asset_version );
                this.updateData();
            },
            resetChanges()
            {
                this.isResetPw = true;
                this.editing = false;
                this.changing_password = false;
                this.current_password = "";
                this.$refs.repeat_password_field.inp_password = "";
                this.$refs.new_password_field.inp_password = "";
                this.$refs.new_password_field.onInput();
                this.$refs.repeat_password_field.onInput();
                this.onSignIn();
            },
            saveChanges()
            {
                this.acting = true;
                const msg = {};
                for( let o in this.entity )
                {
                    if( this.entity.hasOwnProperty( o ) && this._fields[ o ] )
                    {
                        msg[ o ] = this.entity[ o ];
                    }
                }
                this.$store.shioCoreClient.updateProfile( util.getOrganizationId(), msg.name ).then( result =>
                {
                    this.acting = false;
                    this.editing = false;
                    globalEvents.emit( "profile-changed", msg );
                } ).catch( err =>
                {
                    this.acting = false;
                    this.$error( this.$t( "signflow.Error!Unexpected" ) )
                    console.error( "[UserProfile] Error updating profile.", err );
                    this.resetChanges();
                } );
            },
            checkChangePassword( evt )
            {
                if( evt.key === "Enter" )
                {
                    if( this.valid_password_inputs )
                    {
                        this.setPassword();
                    }
                }
            },
            setPassword()
            {
                this.acting = true;
                this.current_password = this.current_password.replace( /[\u200B-\u200F\uFEFF]/g, '' );
                signedInClient.updatePassword( this.current_password,
                this.new_password,
                window.$cc_user_info.scope ).then( result =>
                {
                    signedOutClient.createSessionWithPassword( window.$cc_user_info.scope,
                    this.entity.email,
                    this.new_password ).then( result =>
                    {
                        this.current_password = "";
                        this.new_password = "";
                        this.repeat_password = "";
                        this.changing_password = false;
                        this.acting = false;
                        this.$inform( this.$t( "message.PasswordSet" ) );
                    } ).catch( err =>
                    {
                        this.acting = false;
                        console.error( "[UserProfile] Error signing in after changing password." );
                        globalEvents.emit( "signed-out" );
                    } );
                } ).catch( err =>
                {
                    this.acting = false;
                    console.error( "[UserProfile] Error updating password.", err );
                    if( err.code_key === 401 )
                    {
                        this.current_password = "";
                        this.$refs.current_password_field.focus();
                        this.$error( this.$t( 'message.Error!PasswordChangeFailedInvalidPassword' ) )
                    }
                    else
                    {
                        this.resetChanges();
                        this.$error( this.$t( 'message.Error!UnexpectedError' ) );
                    }
                } );
            },
            updateData()
            {
                this.entity.$dirty = true;
                if( this._fields.address )
                {
                    this.entity.address = this.int_address;
                }
                this.birthDateMenu = false;
            },
            checkSave( evt )
            {
                if( evt.key === "Enter" )
                {
                    if( this.entity.$dirty )
                    {
                        this.saveChanges();
                    }
                }
            },
            onSignIn()
            {
                return new Promise( resolve =>
                {
                    signedInClient.retrieveHandshake().then( result =>
                    {
                        this.signedIn = true;
                        this.entity.$dirty = false;
                        this.entity = result;
                        if( this._fields.address )
                        {
                            this.int_address = this.entity.address || {};
                        }
                        resolve();
                    } ).catch( err =>
                    {
                        globalEvents.emit( "signed-out" );
                        resolve();
                    } );
                } );
            },
            signOut()
            {
                util.logoutAllSessions( this.$store.shioCoreClient );
            },
            validateNewPassword(value)
            {
                this.new_password = value;

                if( this.isResetPw || tester.test( value ).strong )
                {
                    this.isResetPw = false;
                    return this.isPasswordValid = true;
                }
                else
                {
                    this.isResetPw = false;
                    return this.isPasswordValid = this.$t( 'message.PasswordMustValid' );
                }
            },
            validateRepeatPassword(value)
            {
                if( this.isResetPw || value === this.new_password )
                {
                    this.isResetPw = false;
                    return this.isRepeatPasswordValid = true;
                }
                else
                {
                    this.isResetPw = false;
                    return this.isRepeatPasswordValid = this.$t( 'message.RepeatPasswordMustSame' );
                }
            },
        },
        mounted()
        {
            this.onSignIn();
        }
    }
</script>

<style lang="sass" scoped>
  .profile-form input:disabled, .profile-form .v-select__selection--disabled
    color: black !important

  .profile-form .v-input--is-disabled > .v-input__control > .v-input__slot:before
    border: none !important

  .password-requirement
    font-size: 14px
    padding-top: 5px
</style>
