<template>
  <div class="ma-auto mt-4 maxWidth-1100">
    <h1 class="shio-section-title">{{ $t( "signedin.Profile" ) }}</h1>
    <div class="px-1">
    <!-- To control what properties the profile pane displays, modify optional_properties. This is the full list. -->
      <signhero-user-profile class="elevation-1 mt-5" optional_properties=""></signhero-user-profile>
    </div>
  </div>
</template>

<script>
    import SignHeroUserProfile from "../../signedin/profile/SignHeroUserProfile";

    export default {
        name : "ProfilePane",
        components : {
          "signhero-user-profile" : SignHeroUserProfile
        },
    }
</script>

<style lang="sass">
  @import "../../../styles/style"

  .cvw-edit-profile-btn, .cvw-change-password-btn, .cvw-sign-out-btn, .cvw-reset-btn, .cvw-update-btn, .cvw-profile-set-password-btn
    min-width: 200px !important
    height: 40px !important
    font-size: 16px !important
    width: auto
    box-shadow: none

  .cvw-edit-profile-btn, .cvw-change-password-btn, .cvw-reset-btn
    color: $text-black !important
    background-color: white !important
    border: 1px solid $text-black

  .cvw-update-btn.v-btn.v-btn--disabled.v-btn--has-bg, .cvw-profile-set-password-btn.v-btn.v-btn--disabled.v-btn--has-bg
    opacity: 0.5
    color: white !important
    background-color: $vivid-pink !important

  .cvw-update-btn.v-btn.v-btn--has-bg:hover, .cvw-profile-set-password-btn.v-btn.v-btn--has-bg:hover,
  button.cvw-sign-out-btn:hover
    background-color: $dark-pink !important

  .cvw-sign-out-btn, .cvw-update-btn, .cvw-profile-set-password-btn
    margin-left: auto

  .cvw-change-password-title
    display: none

  .v-card__actions.cvw-my-profile-action
    padding-left: 10px
    padding-right: 10px

  @media (max-width: 960px)
    .cvw-sign-out-btn, .cvw-edit-profile-btn, .cvw-change-password-btn, .cvw-reset-btn, .cvw-update-btn, .cvw-profile-set-password-btn
      margin: auto
      width: 100% !important
</style>
