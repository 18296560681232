import Vue from "vue";
import Vuex from "vuex";
import { JSONPureClient } from "@cloudlace/client";
import { globalEvents } from "@cloudlace/client";
import shioCoreServiceMap from "../../api/shio-core-service-map";
import apiManagerServiceMap from "../../api/api-manager-service-map";
import signFlowServiceMap from "../../api/sign-flow-service-map";
import signSessionServiceMap from "../../api/sign-session-signed-in-service-map";
import pdfSignServiceMap from "../../api/pdf-sign-service-map";
import userSignedInServiceMap from "../../api/user-signed-in-service-map";
import groupMergeServiceMap from "../../api/group-merge-service-map";
Vue.use( Vuex );

const shioCoreClient = new JSONPureClient( {
    service_url : process.env.VUE_APP_SERVICE_SIGNEDIN_URL,
    service_map : shioCoreServiceMap,
    websocket_url : process.env.VUE_APP_CLOUDCORE_WEBSOCKET_URL,
    channel : "shio-core-notifications"
} );
const apiManagerClient = new JSONPureClient( {
    service_url : process.env.VUE_APP_API_MANAGER_URL,
    service_map : apiManagerServiceMap,
    websocket_url : process.env.VUE_APP_CLOUDCORE_WEBSOCKET_URL,
    channel : "api-manager-notifications"
} );

const signFlowClient = new JSONPureClient( {
    service_url : process.env.VUE_APP_SIGNFLOW_SIGNFLOW_URL,
    service_map : signFlowServiceMap,
    websocket_url : process.env.VUE_APP_CLOUDCORE_WEBSOCKET_URL,
    channel : "shio-signflow-notifications"
} );
const signSessionClient = new JSONPureClient( {
    service_url : process.env.VUE_APP_SIGNFLOW_SIGN_SESSION_SIGNEDIN_URL,
    service_map : signSessionServiceMap
} );
const pdfSignClient = new JSONPureClient( {
    service_url : process.env.VUE_APP_SIGNFLOW_PDFSIGN_URL,
    service_map : pdfSignServiceMap
} );
const userSignedInClient = new JSONPureClient( {
    service_url : process.env.VUE_APP_USER_SIGNEDIN_URL,
    service_map : userSignedInServiceMap
} );
const groupMergeClient = new JSONPureClient( {
    service_url : process.env.VUE_APP_SCHEDULER_GROUPMERGE_URL,
    service_map : groupMergeServiceMap
} );
/**
 * All I/O against the Cloudcore App server is handled through this data store. It keeps track of those server resources
 * that have been fetched to the client side.
 *
 * @type {Vuex.Store}
 */
const store = new Vuex.Store( {
    state : {
        _fetched : {},
        notes : {}
    },
    mutations : {
    },
    getters : {
    },
    actions : {
    }
} );

// Subscribe to messages sent from the server informing readClient of changes to data.
shioCoreClient.on( "message", ( evt ) =>
{
    globalEvents.emit( "shio-core-notification", evt )
} );
signFlowClient.on( "message", ( evt ) =>
{
    globalEvents.emit( "shio-signflow-notification", evt );
} );
store.shioCoreClient = shioCoreClient;
store.signFlowClient = signFlowClient;
store.userSignedInClient = userSignedInClient;
store.signSessionClient = signSessionClient;
store.pdfSignClient = pdfSignClient;
store.apiManagerClient = apiManagerClient;
store.groupMergeClient = groupMergeClient;
store.client_secrets = {};
export default store;