<template>
  <v-card class="px-8 pt-3" flat>
    <v-responsive>
      <v-form v-model="form_valid">
        <v-text-field @input="updateFields"
                      :label="$t( 'client.ApplicationName' )"
                      v-model="entity.display_name"></v-text-field>
        <v-text-field v-if="entity.created"
                      disabled
                      :label="$t( 'client.CreationDate' )"
                      v-model="creation_time_formatted"></v-text-field>
        <v-text-field disabled
                      :label="$t( 'client.CreatedBy' )"
                      v-model="entity.creator_email"></v-text-field>
        <v-text-field disabled
                      :label="$t( 'signedin.Organization' )"
                      :value="group_name"></v-text-field>
        <v-text-field disabled
                      :label="$t( 'client.AllowedGrantTypes' )"
                      v-model="grant_types_formatted"></v-text-field>
        <v-textarea @input="updateFields"
                    :label="$t( 'client.AppDescription' )"
                    v-model="entity.description" rows="2"></v-textarea>
        <v-text-field @input="updateFields"
                      :rules="[rules.domain]"
                      :readonly="!entity.$new"
                      :label="$t( 'client.OAuthClientsDomainTitle' )"
                      v-model="entity.domain">
          <v-icon v-if="!entity.$new && entity.domain_validated" slot="append-outer" color="green">
            mdi-check
          </v-icon>
          <v-icon v-if="!entity.$new && !entity.domain_validated" slot="append-outer" color="red">
            mdi-alert
          </v-icon>
        </v-text-field>
        <p v-if="entity.$new" v-html="$t( 'client.CheckApiDocs' )" class="text-left singleQuestion-container"></p>
        <div v-if="entity.domain">
          <div v-if="entity.domain_validation_string && !entity.domain_validated">
            <p class="pink lighten-5 pa-4 text-left" v-html="$t( 'client.WhyValidateDomain' )">
            </p>
            <div class="text-left">
              <div>{{ $t( 'client.DomainValidationDownloadInfo1' ) }}</div>
              <ol>
                <li>{{ $t( 'client.DomainValidationDownloadInfo2' ) }} <a class="paragraph-links" :download='domain_validation_filename' :href='domain_validation_data'>{{ domain_validation_filename }}</a></li>
                <li>{{ $t( 'client.DomainValidationDownloadInfo3' ) }} <a class="paragraph-links" :href='domain_href'>{{ domain_href }}</a></li>
                <li>{{ $t( 'client.DomainValidationDownloadInfo4' ) }} <a class="paragraph-links" :href='domain_validation_link'>{{ domain_validation_link }}</a></li>
                <li>{{ $t( 'client.DomainValidationDownloadInfo5' ) }}</li>
              </ol>
            </div>
            <p class="text-sm-right text-xs-center py-3">
              <v-btn @click="validateDomain" class="primary-btn">{{ $t( 'client.VerifyDomain' ) }}</v-btn>
            </p>
          </div>
          <h5 class="text-left">{{ $t( 'client.OAuthRedirectURIs' ) }}</h5>
          <v-text-field class="pt-0 redirect-uri"
                        v-for="( uri, index ) in redirect_uris"
                        @input="updateFields"
                        :prefix="redirect_prefix"
                        :key="index"
                        v-model="redirect_uris[ index ].uri">
            <v-icon v-if="index > 0" slot="append-outer" color="textBlack" :key="index" @click="deleteRedirectUri( index )">
              mdi-close
            </v-icon>
          </v-text-field>
          <v-row no-gutters>
            <v-col cols="12" sm="6" md="4" class="pb-3">
              <v-btn block :disabled="!entity.$dirty || working" @click="resetEntity" class="secondary-btn" v-if="!entity.$new">
                {{ $t( "signedin.Reset" ) }}
              </v-btn>
            </v-col>
            <v-col class="hidden-sm-and-down" cols="4">
              <v-spacer/>
            </v-col>
            <v-col cols="12" sm="6" md="4" class="pb-3">
              <v-btn block @click="addRedirectUri" class="ml-auto primary-btn">{{ $t( 'client.AddUri' ) }}</v-btn>
            </v-col>
          </v-row>
        </div>
        <div v-if="!entity.$new">
          <h3 class="font-weight-bold mt-6">{{ $t( 'client.ClientCredentials' ) }}</h3>
          <v-text-field readonly :label="$t( 'client.ClientId' )" v-model="client_short_id"></v-text-field>
          <v-text-field readonly :label="$t( 'client.ClientSecret' )" v-model="client_secret"></v-text-field>
          <v-text-field v-if="entity.secret_updated"
                        disabled
                        :label="$t( 'client.LastClientSecretReset' )"
                        v-model="secret_updated_formatted"></v-text-field>
          <p class="text-sm-right text-xs-center">
            <v-btn @click="updateClientSecret" class="alternative-primary-btn">{{ $t( 'client.ResetClientSecret' ) }}</v-btn>
          </p>
          <div v-if="client_secret">
            <p class="pink lighten-5 pa-4 mt-6 text-left">{{ $t( 'client.ClientSecretInfo' ) }}</p>
          </div>
        </div>
      </v-form>
    </v-responsive>
    <v-divider class="mt-3"></v-divider>
    <v-card-actions class="px-0 pb-0 ma-auto">
      <v-row no-gutters>
        <v-col  cols="12" sm="6" md="4" class="py-3">
          <v-btn block v-if="!entity.$new" :disabled="working" @click="deleteEntity" class="secondary-btn">
            {{ $t( "signedin.Delete" ) }}
          </v-btn>
        </v-col>
        <v-col class="hidden-sm-and-down" cols="4">
          <v-spacer/>
        </v-col>
        <v-col cols="12" sm="6" md="4" class="py-3">
          <v-btn block :disabled="!entity.$dirty || working || !valid || !entity.domain"
                 v-if="!entity.$new" @click="prepareAndSave" class="ml-auto primary-btn">{{ $t( "signedin.Update" ) }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
    <v-card-actions class="pa-0 ma-auto">
      <v-row no-gutters>
        <v-col md="12" lg="12" cols="12" class="py-1 text-center">
          <p v-if="entity.$new" class="singleQuestion-container" v-html="$t( 'signedin.AcceptApiTerms' )"></p>
          <v-btn block class="primary-btn ma-auto" :disabled="working || !valid || !entity.domain" v-if="entity.$new"
                 @click="prepareAndCreate">{{ $t( "signedin.Create" ) }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
    import Vue from "vue";
    import {format} from "date-fns";
    import {globalEvents, JSONPureClient} from "@cloudlace/client";
    import apiManagerServiceMap from "../../../api/api-manager-service-map";
    import util from "../../../util/util";
    const UPDATE_WHITELIST = [ "display_name", "description", "redirect_uris" ];
    export default {
        name : "ClientCard",
        props : {
            entity : Object
        },
        data()
        {
            const domainRe = /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/;
            return {
                group_name : $cc_user_info.organization.display_name,
                rules : {
                    domain : str => {
                        return str === "" || domainRe.test( str ) || this.$t( "client.Error!InvalidDomain" );
                    }
                },
                form_valid : false,
                delete_confirmed : false,
                error_message : "",
                name_prop : "name",
                working : false,
                expanded : false,
                blacklist : [ "name", "creator_email", "domain", "domain_validated", "secret_updated", "domain_validation_string" ],
                createBlackList : [ "secret_updated", "domain_validation_string" ],
                client_secret : undefined,
                client_short_id : undefined,
                redirect_prefix : undefined,
                grant_type_refresh_token : false,
                grant_type_authorization_code : false,
                domain_validation_filename : "signhero-association.txt",
                redirect_uris : [ { uri : "" } ],
                redirect_uris_backup : [],
                client_types : [ "confidential", "public" ]
            }
        },
        computed : {
            secret_updated_formatted()
            {
                return this.formatTimestamp( this.entity.secret_updated )
            },
            creation_time_formatted()
            {
                return this.formatTimestamp( this.entity.created )
            },
            domain_validation_link()
            {
                return this.domain_href + '/' + this.domain_validation_filename;
            },
            domain_validation_data()
            {
                return "data:text/plain," + this.entity.domain_validation_string;
            },
            grant_types_formatted()
            {
                return this.entity.grant_types.join( ", " );
            },
            domain_href()
            {
                return "https://" + this.entity.domain
            },
            valid()
            {
                return this.form_valid && this.entity.display_name && this.entity.creator_email && this.entity.domain;
            }
        },
        methods : {
            formatTimestamp( ts )
            {
                if( ts )
                {
                    return format( new Date( parseInt( ts ) ), this.$t( "signedin.DateTimeFormat" ) )
                }
                else
                {
                    return "";
                }
            },
            deleteEntity()
            {
                const rndCode = util.getRandomCode();
                const text = eval( '`' + this.$t( "client.ConfirmDeleteApiClient" ) + '`' );
                const cancelText = this.$t( "signflow.Cancel" );
                const acceptText = this.$t( "signflow.Confirm" );
                this.$prompt( { text, cancelText, acceptText } ).then( r =>
                {
                  if( r === rndCode )
                  {
                    this.doDeleteEntity();
                  }
                  else
                  {
                    this.$warn( this.$t( "signedin.IncorrectConfirmationCode" ) );
                  }
                } ).catch( e => {} );
            },
            doDeleteEntity()
            {
                {
                    this.$store.apiManagerClient.deleteApp( $cc_user_info.organization.uuid, this.entity.name ).then( () =>
                    {
                        const appName = this.entity.display_name;
                        this.$parent.$inform( eval( '`' + this.$t( 'client.AppHasBeenDeleted' ) + '`' ) );
                        this.$emit( "close" );
                    } ).catch( e => { this.$error( this.$t( "signedin.Error!Unexpected" ) ) } );
                }
            },
            resetEntity()
            {
                this.resetRedirectUris();
                this.$emit( 'refresh-data' );
            },
            addRedirectUri()
            {
                Vue.set( this.entity, "$dirty", true );
                this.redirect_uris.push( { uri : "" } );
            },
            updateFields()
            {
                Vue.set( this.entity, "$dirty", true );
                this.redirect_prefix = "https://" + this.entity.domain + "/";
            },
            updateClientSecret()
            {
                const rndCode = util.getRandomCode();
                const text = eval( '`' + this.$t( "client.ConfirmResetClientSecret" ) + '`' );
                const acceptText = this.$t( "signflow.Confirm" );
                const cancelText = this.$t( "signflow.Cancel" );
                this.$prompt( { text, acceptText, cancelText } ).then( r =>
                {
                  if( r === rndCode )
                  {
                    this.doupdateClientSecret();
                  }
                  else
                  {
                    this.$warn( this.$t( "signedin.IncorrectConfirmationCode" ) );
                  }
                } ).catch( e => {} );
            },
            doupdateClientSecret()
            {
                this.$store.apiManagerClient.updateClientSecret( $cc_user_info.organization.uuid, this.entity.name ).then( r =>
                {
                    this.$store.client_secrets[ this.entity.name ] = r.client_secret;
                    this.client_secret = r.client_secret;
                    this.$parent.$inform( this.$t( 'client.ClientSecretChanged' ) );
                    this.resetEntity();
                } ).catch( e => this.$error( this.$t( "signedin.Error!Unexpected" ) ) );
            },
            validateDomain()
            {
                const apiManagerClient = new JSONPureClient( {
                    service_url : process.env.VUE_APP_API_MANAGER_URL,
                    service_map : apiManagerServiceMap
                } );
                apiManagerClient.updateDomainValidity( $cc_user_info.organization.uuid, this.entity.name ).then( r =>
                {
                    Vue.set( this.entity, "domain_validated", true );
                    this.$inform( this.$t( 'client.DomainVerified' ) );
                } ).catch( e =>
                {
                    if( e.code_key === 403 )
                    {
                        Vue.set( this.entity, "domain_validated", false );
                        this.$error( this.$t( 'client.FailedDomainValidation' ) );
                    }
                    else
                    {
                        this.$error( this.$t( "signedin.Error!Unexpected" ) )
                    }
                } );
            },
            deleteRedirectUri( i )
            {
                Vue.set( this.entity, "$dirty", true );
                this.redirect_uris.splice( i, 1 );
            },
            prepare()
            {
                this.entity.domain_validation_uri = this.domain_validation_link;
                this.redirect_uris = this.redirect_uris.map( uri => {
                    return { ...uri, uri : uri.uri.replaceAll( ' ', '' ) }
                } );
                this.backupRedirectUris();
                this.entity.redirect_uris = this.redirect_uris.map( uri => this.domain_href + "/" + uri.uri );
                this.entity.grant_types = [];
                if( this.grant_type_authorization_code )
                {
                    this.entity.grant_types.push( "authorization_code" );
                }
                if( this.grant_type_refresh_token )
                {
                    this.entity.grant_types.push( "refresh_token" );
                }
            },
            prepareAndCreate()
            {
                this.working = true;
                this.prepare();
                const ent = { ...this.entity };
                delete ent.$new;
                delete ent.$dirty;
                delete ent.creator_email;
                this.$store.apiManagerClient.createApp( $cc_user_info.organization.uuid, ent ).then( r =>
                {
                    this.working = false;
                    this.entity.$new = false;
                    this.entity.name = r.client_id;
                    this.client_secret = r.client_secret;
                    if( this.entity.name )
                    {
                        this.client_short_id = this.entity.name.split( "." )[ 1 ];
                    }
                    this.$store.client_secrets[ this.entity.name ] = r.client_secret;
                    const appName = this.entity.display_name;
                    const text = eval( '`' + this.$t( 'client.AppHasBeenCreated' ) + '`' );
                    const acceptText = this.$t( "signflow.Ok" );
                    this.$parent.$alert( { text, acceptText } );
                    this.resetEntity();
                } ).catch( e =>
                {
                    this.working = false;
                    this.$error( this.$t( "signedin.Error!Unexpected" ) );
                } );
            },
            prepareAndSave()
            {
                this.working = true;
                this.prepare();
                const ent = util.whiteList( this.entity, UPDATE_WHITELIST );
                this.$store.apiManagerClient.updateApp( $cc_user_info.organization.uuid, this.entity.name, ent ).then( () =>
                {
                    this.working = false;
                    Vue.set( this.entity, "$dirty", false );
                    this.$inform( this.$t( 'client.YourChangesHaveBeenSaved' ) );
                } ).catch( e =>
                {
                    this.working = false;
                    this.$error( this.$t( "signedin.Error!Unexpected" ) );
                } );
            },
            loadRedirectUris()
            {
                this.redirect_uris = ( this.entity.redirect_uris || [ "" ] ).map( uri => { return { uri : uri.substring( this.domain_href.length + 1 ) } } );
                this.backupRedirectUris();
            },
            backupRedirectUris()
            {
                this.redirect_uris_backup = this.redirect_uris.map( uri => { return { uri : uri.uri } } );
            },
            resetRedirectUris()
            {
                this.redirect_uris = this.redirect_uris_backup.map( uri => { return { uri : uri.uri } } );
            }
        },
        mounted()
        {
            this.redirect_prefix = this.entity.domain ? this.domain_href + "/" : "";
            this.loadRedirectUris();
            this.grant_type_authorization_code = ( this.entity.grant_types || [] ).includes( "authorization_code" );
            this.grant_type_refresh_token = ( this.entity.grant_types || [] ).includes( "refresh_token" );
            this.entity.creator_email = this.entity.creator_email || $cc_user_info.email;
            if( this.entity.name )
            {
                this.client_short_id = this.entity.name.split( "." )[ 1 ];
            }
            if( this.$store.client_secrets[ this.entity.name ] )
            {
                this.client_secret = this.$store.client_secrets[ this.entity.name ];
            }
        }
    }
</script>

<style lang="sass" scoped>
  @import '../../../styles/style'

  @media (max-width: 599px)
    .v-btn
      margin: auto !important

    button
      width: 100%
</style>
