<template>
  <v-card flat class="pt-4 maxWidth-1100 ma-auto">
    <v-overlay
        v-if="stripePaymentOn && ( !subscription.has_trial_period && !subscription.has_valid_subscription && !subscription.has_payment_past_due
        && !subscription.incomplete_subscription ) || ( stripePaymentOn && subscription.incomplete_subscription && !subscription.default_pm )"
        :absolute="absolute"
        :value="overlay"
        class="no-active-subscription-overlay"
    >
      <p class="font-weight-bold no-active-subscription-notice" v-if="!is_subscription_admin">{{ $t( "signedin.ContactAdmin" ) }}</p>
      <p class="font-weight-bold no-active-subscription-notice" v-if="is_subscription_admin">{{ $t( "signedin.PleaseAddValidCPayment" ) }}</p>
    </v-overlay>
    <v-alert
        v-if="stripePaymentOn && subscription.has_trial_period && !subscription.hideBanner && subscription.trial_days_left <=14"
        color="info"
        dark
        dismissible
        @click="hideBanner"
        class="text-left"
    >
      {{ $t( "signedin.FreeTrialAlert1" ) }} {{subscription.trial_days_left}} {{ $t( "signedin.FreeTrialAlert2" ) }}
    </v-alert>
    <v-alert
        v-if="stripePaymentOn && subscription.has_payment_past_due && !subscription.hideBanner"
        color="info"
        dark
        dismissible
        @click="hideBanner"
        class="text-left"
    >
      {{ $t( "signedin.PaymentOverdueAlert" ) }}
    </v-alert>
    <v-alert
        v-if="stripePaymentOn && subscription.incomplete_subscription && is_subscription_admin && !subscription.hideBanner"
        color="info"
        dark
        dismissible
        @click="hideBanner"
        class="text-left"
    >
      {{ $t( "signedin.PleaseConfirmPaymentMainPage" ) }}
    </v-alert>
    <h1 class="shio-section-title">{{ $t( "signflow.OngoingProcess" ) }}</h1>
    <v-responsive class="pt-5 px-4">
      <v-tabs class="ongoing-tabs">
        <v-tab class="tab-title">{{ $t( "signflow.ProcessesTabName1" ) }}</v-tab>
        <v-tab class="tab-title">{{ $t( "signflow.ProcessesTabName2" ) }}</v-tab>
        <v-tab-item class="text-left">
          <div>
            <v-row no-gutters class="search-process-area">
              <v-text-field v-model="search" append-icon="mdi-magnify" :label="$t( 'signflow.SearchSignFlows' )"
                            @keydown="onSearchChange()"/>
            </v-row>
            <v-radio-group class="search-process-area" v-model="filter" @change="resetSignFlows()">
              <v-row no-gutters>
                <v-col cols="12" sm="4">
                  <v-radio value="all" :label="$t( 'signflow.AllFlows' )"/>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-radio value="me" :label="$t( 'signflow.PendingMeFlows' )"/>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-radio value="others" :label="$t( 'signflow.PendingOthersFlows' )"/>
                </v-col>
              </v-row>
            </v-radio-group>
            <v-row class="search-process-area" no-gutters v-if="is_admin">
              <v-checkbox class="ma-0" v-model="all_in_group" :label="$t( 'signflow.ShowGroupProcesses' )" @change="resetSignFlows()"/>
            </v-row>
            <v-expansion-panels focusable>
              <sh-ongoing-flow class="my-2" v-for="flow in sign_flows" :key="flow.uuid" :flow="flow"/>
            </v-expansion-panels>
            <infinite-loading
                v-if="!stripePaymentOn || subscription.has_trial_period || subscription.has_valid_subscription || subscription.has_payment_past_due || subscription.incomplete_subscription"
                :identifier="infiniteId" @infinite="infiniteHandler">
              <div slot="no-more"></div>
              <div slot="no-results"></div>
              <v-progress-circular slot="spinner" size="48" indeterminate/>
            </infinite-loading>
            <div v-if="!loading && ( sign_flows.length === 0 )" class="sh-no-processes-message">
              <v-icon>mdi-magnify</v-icon> {{ $t( "signflow.NoProcessesFound" ) }}
            </div>
          </div>
        </v-tab-item>
        <v-tab-item class="text-left">
          <v-row no-gutters class="search-process-area">
            <v-text-field v-model="invitedSearch" append-icon="mdi-magnify" :label="$t( 'signflow.SearchSignFlows' )"
                          @keydown="onInvitedSearchChange()"/>
          </v-row>
          <v-row no-gutters>
            {{ $t( "signflow.ShowOnly12MonthInfo" ) }}
          </v-row>
          <v-expansion-panels focusable>
            <sh-ongoing-flow class="my-2" v-for="flow in invited_sign_flows" :key="flow.uuid" :flow="flow" :isSigningInvitation="true"/>
          </v-expansion-panels>
          <infinite-loading
              v-if="!stripePaymentOn || subscription.has_trial_period || subscription.has_valid_subscription || subscription.has_payment_past_due || subscription.incomplete_subscription"
              :identifier="infiniteIdForInvited" @infinite="infiniteHandlerForInvited">
            <div slot="no-more"></div>
            <div slot="no-results"></div>
            <v-progress-circular slot="spinner" size="48" indeterminate/>
          </infinite-loading>
          <div v-if="!loading && ( invited_sign_flows.length === 0 ) && ( invitedSearch.length !== 0 )" class="sh-no-processes-message">
            <v-icon>mdi-magnify</v-icon> {{ $t( "signflow.NoProcessesFound" ) }}
          </div>
        </v-tab-item>
      </v-tabs>
    </v-responsive>
  </v-card>
</template>

<script>
    import _OngoingFlowCard from "./_OngoingFlowCard";
    import { globalEvents } from "@cloudlace/client";
    import util from "../../../../util/util";
    import InfiniteLoading from 'vue-infinite-loading';
    let onServerMessage;
    const paginationLimit = 100;

    export default {
        name : "start-flow",
        components : {
            "sh-ongoing-flow" : _OngoingFlowCard,
            InfiniteLoading
        },
        data()
        {
            return {
                search : "",
                filter : "all",
                all_in_group : false,
                sign_flows : [],
                invited_sign_flows : [],
                is_admin : util.getIsAdmin(),
                is_subscription_admin : util.getIsSubscriptionAdmin(),
                groupId : $cc_user_info.organization.uuid,
                from : 0,
                infiniteId : 0,
                loading : true,
                subscription : $cc_user_info.subscription,
                stripePaymentOn : $cc_user_info.stripePaymentOn,
                invitedSearch : "",
                invited_from : 0,
                infiniteIdForInvited : 0,
                invited_loading : true
            };
        },
        /**
         * Map this.items to state.notes through the Vuex mapState utility.
         */
        computed : {},
        methods : {
            /**
             * Hides subscription time banner.
             */
            hideBanner()
            {
                $cc_user_info.subscription.hideBanner = true;
            },
            /**
             * Search the Signflows from the start, reset the infinite scroll
             */
            resetSignFlows() {
                this.loading = true;
                this.sign_flows = [];
                this.from = 0;
                this.infiniteId ++;
            },
            /**
             * Search the invited Signflows from the start, reset the invited infinite scroll
             */
            resetInvitedSignFlows()
            {
                this.invited_loading = true;
                this.invited_sign_flows = [];
                this.invited_from = 0;
                this.infiniteIdForInvited ++;
            },
            /**
             * Retrieve list of sign flows matching user's search criteria. Implementing infinite scrolling.
             */
            infiniteHandler( $state )
            {
                this.loading = true;
                const props = {
                    completed : false,
                    limit : paginationLimit,
                    from : this.from,
                };
                if( this.search )
                {
                    props.search = this.search;
                }
                if( this.filter === "me" )
                {
                    props.pendingMe = $cc_user_info.sub;
                }
                else if( this.filter === "others" )
                {
                    props.pendingOthers = true;
                }
                const currentFrom = this.from;
                this.$store.signFlowClient.retrieveSignFlows( this.groupId, this.all_in_group ? "group" : "user", props ).then( r =>
                {
                    if( !currentFrom ) { this.sign_flows = []; }
                    if ( r.signFlows.length )
                    {
                        this.sign_flows.push( ...r.signFlows );
                        this.loading = false;
                        if ( r.signFlows.length < paginationLimit )
                        {
                            $state.complete();
                        }
                        else
                        {
                            this.from = currentFrom + paginationLimit;
                            $state.loaded();
                        }

                    }
                    else
                    {
                        this.loading = false;
                        $state.complete();
                    }

                } ).catch( e => this.$error( this.$t( "signflow.Error!Unexpected" ) ) );
            },
            /**
             * Retrieve list of sign flows matching user's search criteria. Implementing infinite scrolling for external signflows.
             */
            infiniteHandlerForInvited( $state )
            {
                this.invited_loading = true;
                const props = {
                    completed : false,
                    limit : paginationLimit,
                    from : this.invited_from,
                };
                if( this.invitedSearch )
                {
                    props.search = this.invitedSearch;
                }
                const currentFrom = this.invited_from;
                this.$store.signFlowClient.retrieveSignFlows( this.groupId, "invitations", props ).then( r =>
                {
                    if( !currentFrom ) { this.invited_sign_flows = []; }
                    if ( r.signFlows.length )
                    {
                        this.invited_sign_flows.push( ...r.signFlows );
                        this.invited_loading = false;
                        if ( r.signFlows.length < paginationLimit )
                        {
                            $state.complete();
                        }
                        else
                        {
                            this.invited_from = currentFrom + paginationLimit;
                            $state.loaded();
                        }
                    }
                    else
                    {
                        this.invited_loading = false;
                        $state.complete();
                    }
                } ).catch( e => this.$error( this.$t( "signflow.Error!Unexpected" ) ) );
            },
            /**
             * Push search timeout forward.
             */
            onSearchChange()
            {
                if( this._searchTimeout )
                {
                    clearTimeout( this._searchTimeout );
                }
                this._searchTimeout = setTimeout( () =>
                {
                    this.resetSignFlows();
                }, 500 ) ;
            },
            /**
             * Push invited search timeout forward.
             */
            onInvitedSearchChange()
            {
                if( this._searchTimeout )
                {
                    clearTimeout( this._searchTimeout );
                }
                this._searchTimeout = setTimeout( () => this.resetInvitedSignFlows(), 500 );
            },
            /**
             * If evt was about sign flows, refresh data.
             *
             * @param evt
             * @private
             */
            _onServerMessage( evt )
            {
                const msg = evt.detail;
                if( msg.data_type === "sign_flow" )
                {
                    this.resetSignFlows();
                    this.resetInvitedSignFlows();
                }
            }
        },
        /**
         * Subscribe to sign flow notifications.
         */
        mounted()
        {
            onServerMessage = this._onServerMessage.bind( this );
            globalEvents.on( "shio-signflow-notification", onServerMessage );
        },
        /**
         * Remove notification listener.
         */
        beforeDestroy()
        {
            globalEvents.remove( "shio-signflow-notification", onServerMessage );
        }
    }
</script>

<style lang="sass" scoped>
  @import "../../../../styles/style"

  .sh-no-processes-message
    font-weight: bold

  .v-item-group.v-expansion-panels.v-expansion-panels--focusable
    padding: 2px

  .v-tab.v-tab--active
    color: $vivid-pink !important
</style>

<style lang="sass">
  @import "../../../../styles/style"

  .sh-flow-panel-header i.v-icon.mdi.mdi-chevron-down
    color: $vivid-pink !important

  .tab-title
    text-transform: none
    font-size: 20px
    color: $text-black !important
    letter-spacing: normal
</style>
