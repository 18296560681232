import { render, staticRenderFns } from "./NewAppToken.vue?vue&type=template&id=22ff2637&scoped=true"
import script from "./NewAppToken.vue?vue&type=script&lang=js"
export * from "./NewAppToken.vue?vue&type=script&lang=js"
import style0 from "./NewAppToken.vue?vue&type=style&index=0&id=22ff2637&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22ff2637",
  null
  
)

export default component.exports