import util from "../../../util/util";
import Color from "color";
import dateFormat from "dateformat";
/**
 * Mixin with common sign flow creation features.
 */
export default {
    computed : {
        resolution()
        {
            const day = 24 * 3600 * 1000;
            const week = day * 7;
            const month = day * 30;
            const year = day * 365;
            const span = this.to - this.from;
            if( span < week )
            {
                return "hour";
            }
            else if( span < month * 2 )
            {
                return "day";
            }
            else if( span < year )
            {
                return "week";
            }
            else
            {
                return "month";
            }
        },
        fullLocale()
        {
            switch ( util.getLocale() )
            {
                case "fi":
                    return "fi-FI";
                case "sv":
                    return "sv-SE";
                case "pl":
                    return "pl-PL";
                case "de":
                    return "de-DE";
                case "nl":
                    return "nl-NL";
                case "fr":
                    return "fr-FR";
                case "es":
                    return "es-ES";
                case "th":
                    return "th-TH";
                default:
                    return "en-GB";
            }
        },
        formatFromDate()
        {
            return dateFormat( new Date( this.from_date ), "dd.mm.yyyy" );
        },
        formatToDate()
        {
            return dateFormat( new Date( this.to_date ), "dd.mm.yyyy" );
        },
        from()
        {
            return new Date( this.from_date + 'T00:00:00.000Z' ).getTime();
        },
        to()
        {
            return new Date( this.to_date + 'T23:59:59.999Z' ).getTime();
        },
    },
    methods : {
        assignColors( datasets, chart )
        {
            this.color = 0;
            datasets.forEach( dataset =>
            {
                if( Math.max.apply( Math, dataset.data ) === 0 )
                {
                    dataset.borderColor = "#ddd";
                    dataset.backgroundColor = '#fff';
                }
                else
                {
                    const stacked = chart.data.options
                                    && chart.data.options.scales
                                    && chart.data.options.scales.yAxes
                                    && chart.data.options.scales.yAxes.filter( axis => axis.stacked ).length
                                    > 0;
                    const color = this._nextColor();
                    dataset.borderColor = color.border;
                    dataset.backgroundColor = stacked ? color.opaqueBackground : color.background;
                }
            } );
        },
        _nextColor()
        {
            const color = new Color( this.colors[ this.color % this.colors.length ] );
            this.color++;
            return {
                border : color.rgb().toString(),
                background : color.fade( 0.9 ).rgb().toString(),
                opaqueBackground : color.lighten( 0.6 ).rgb().toString()
            };
        },
        selectUsers()
        {
            this.loadChart( true );
        },
        itemRowFontColor: function( item )
        {
            return  item.active ? 'u-active' : 'u-inactive';
        }
    }
}